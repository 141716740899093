export const BoxingIcon = ({ className }: { className: string }) => {
    const newClassName = className.replace('fill-white', 'fill-black')
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="22"
            viewBox="0 0 16 22"
            className={newClassName}
        >
            <path
                d="M3.33331 1H12.6666"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.74665 5H7.25331C5.51287 6.6 1.33331 11.4178 1.33331 14.3333C1.33331 18.0152 4.31808 21 7.99998 21C11.6819 21 14.6666 18.0152 14.6666 14.3333C14.6666 11.4178 10.4871 6.60178 8.74665 5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.29865 5H7.70131C7.00531 6.6 5.33331 11.4178 5.33331 14.3333C5.33331 18.0151 6.52709 21 7.99998 21C9.47287 21 10.6666 18.0151 10.6666 14.3333C10.6666 11.4178 8.99465 6.60178 8.29865 5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 1V5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
