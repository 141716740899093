import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { headerLendingRoutesArr } from '../../../../assets/constants/headerLendingRoutesArr'

interface IProps {
    setBurgerMenuOpen?: Dispatch<SetStateAction<boolean>>
}

export const LendingItems = ({ setBurgerMenuOpen }: IProps) => {
    const [activeSection, setActiveSection] = useState(
        headerLendingRoutesArr[0]
    )

    useEffect(() => {
        const top = (name: string) =>
            (document.getElementById(name)?.offsetTop || 0) +
                (name === 'Watch' ? 20 : 0) || 0
        const handleScroll = () => {
            const scrollPosition = document.body.scrollTop

            headerLendingRoutesArr.map((el, i) =>
                i === 0
                    ? scrollPosition < top(el) && setActiveSection(el)
                    : i === headerLendingRoutesArr.length - 1
                      ? scrollPosition >= top(el) && setActiveSection(el)
                      : scrollPosition >= top(el) &&
                        scrollPosition < top(headerLendingRoutesArr[i + 1]) &&
                        setActiveSection(el)
            )
        }

        document.body.addEventListener('scroll', handleScroll)

        return () => {
            document.body.removeEventListener('scroll', handleScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <p className="lg:px-4 flex flex-col lg:flex-row lg:justify-between">
            {headerLendingRoutesArr.map((el, i) => (
                <span
                    onClick={() => {
                        const top = document.getElementById(el)?.offsetTop
                        document.body.scrollTo({
                            top,
                            behavior: 'smooth',
                        })
                        setBurgerMenuOpen?.(false)
                    }}
                    key={i}
                    className={`${activeSection === el ? 'text-violet' : 'text-white'} py-2 lg:py-0 cursor-pointer text-sm pr-2 header:pr-4 hover:text-violet`}
                >
                    {el}
                </span>
            ))}
        </p>
    )
}
