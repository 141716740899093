import React from 'react';

export const PaymentHistoryIcon = ({className}: {className: string}) => {
  return (
    <svg className={className} viewBox="0 0 32 32">
      <g id="_21-30" data-name="21-30">
        <g id="Wallet">
          <path d="M29,15.278V12a3,3,0,0,0-2-2.816V7.5A3.5,3.5,0,0,0,23.5,4H5.5A3.5,3.5,0,0,0,2,7.5V23a5.006,5.006,0,0,0,5,5H24a5.006,5.006,0,0,0,5-5V21.722A1.993,1.993,0,0,0,30,20V17A1.993,1.993,0,0,0,29,15.278ZM28,17l0,3H22.5a1.5,1.5,0,0,1,0-3ZM5.5,6h18A1.5,1.5,0,0,1,25,7.5V9H5.5a1.5,1.5,0,0,1,0-3ZM24,26H7a3,3,0,0,1-3-3V10.649A3.464,3.464,0,0,0,5.5,11H26a1,1,0,0,1,1,1v3H22.5a3.5,3.5,0,0,0,0,7H27v1A3,3,0,0,1,24,26Z"/>
        </g>
    </g>
    </svg>
  );
};
