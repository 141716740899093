import React from 'react'

export const PricingIcon = ({ className }: { className: string }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="#fff"
                fill-rule="evenodd"
                d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20ZM10 9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1 1 1 0 1 0 2 0 3 3 0 0 0-3-3V5a1 1 0 1 0-2 0v1a3 3 0 0 0-3 3v.56a3 3 0 0 0 2.05 2.84l3.27 1.1a1 1 0 0 1 .68.94V15a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1 1 1 0 1 0-2 0 3 3 0 0 0 3 3v1a1 1 0 1 0 2 0v-1a3 3 0 0 0 3-3v-.56a3 3 0 0 0-2.05-2.84l-3.27-1.1a1 1 0 0 1-.68-.94V9Z"
                clip-rule="evenodd"
            />
        </svg>
    )
}
