import React, {
    createContext,
    lazy,
    useState,
    Suspense,
    useEffect,
} from 'react'
import { IEvent } from './api/dto/IEvent'
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useParams,
} from 'react-router-dom'
import { getCookie } from './assets/constants/getCookie'
import { CloseIcon } from './assets/icons/closeIcon'
import {
    blackBackGround,
    mainBackGround,
} from './assets/constants/themeBackgrounds'
import TrialOverModal from './modal/TrialOverModal'
import CustomImage from './components/customImage/customImage'
import { IStream } from './api/dto/IStream'
import { LocalStorageItems } from './constant/localStorageItems'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './config/query-client'
import ReactGA from 'react-ga4'
import usePageTracking from './hooks/usePageTracking'
import { APP_PATHS } from './constant/appPaths'
import { SPORTS } from './constant/sports'
import { CRM_API_URL } from './assets/constants/crmApi'
import { Helmet } from 'react-helmet'
import useProfileQuery from './queries/use-profile-query'
import PricingModal from './layout/components/header/components/pricing/pricingModal'
import { checkUserSubscription } from './utils/token'
import Pricing from './pages/pricing/pricing'

ReactGA.initialize('G-099Z35WBYB')

interface InitialStateContextInterface {
    differanceTimeZoneInMs: number
    setTimeZone: (ms: number) => void
    modal: 'img' | 'custom' | ''
    setModal: (modal: 'img' | 'custom' | '') => void
    landingModal: 'add' | 'remove' | ''
    setLandingModal: (modal: 'add' | 'remove' | '') => void
    landings: string[]
    setLandings: (landings: string[]) => void
    token: string
    setToken: (token: string) => void
    currentSport: SPORTS
    setCurrentSport: (sport: SPORTS) => void
}

const InitialState: InitialStateContextInterface = {
    differanceTimeZoneInMs: 1000,
    setTimeZone(ms) {
        InitialState.differanceTimeZoneInMs = ms
    },
    modal: '',
    setModal(modal) {
        InitialState.modal = modal
    },
    landingModal: '',
    setLandingModal(modal) {
        InitialState.landingModal = modal
    },
    landings: [],
    setLandings(landings) {
        InitialState.landings = landings
    },
    token: localStorage.getItem(LocalStorageItems.TOKEN) || '',
    setToken(token) {
        InitialState.token = token
    },
    currentSport: SPORTS.CHANNELS,
    setCurrentSport(sport) {
        InitialState.currentSport = sport
    },
}

export const InitialStateContext =
    createContext<InitialStateContextInterface>(InitialState)

export interface IEventWithStream {
    stream?: IStream[]
    event?: IEvent
}

const TvPage = lazy(() => import('./pages/tvPage/tvPage'))
const FAQ = lazy(() => import('./pages/FAQ/FAQ'))
const Help = lazy(() => import('./pages/help/help'))
const ContactUs = lazy(() => import('./pages/contactUs/contactUs'))
const TermsAndConditions = lazy(
    () => import('./pages/termsAndConditions/termsAndConditions')
)
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy/privacyPolicy'))
const SessionRenew = lazy(() => import('./pages/SessionRenew'))
// const RefundPolicy = lazy(() => import("./components/pages/refundPolicy/refundPolicy"));
const MainLending = lazy(
    () => import('./components/lendingComponents/mainLending')
)
const BoxingLending = lazy(
    () => import('./components/lendingComponents/boxingLending')
)

const LandingModal = lazy(
    () => import('./components/landingModal/landingModal')
)
const LandingModalRemove = lazy(
    () => import('./components/landingModal/landingModalRemove')
)

const ExternalRedirect = () => {
    const { pathParam } = useParams()

    useEffect(() => {
        const externalUrl = `${CRM_API_URL}/${pathParam}`
        window.location.href = externalUrl
    }, [pathParam])

    return null
}

export const App = () => {
    const email = getCookie('sessionId') || ''
    const { pathname } = useLocation()
    const [{ differanceTimeZoneInMs }, setTimeZone] = useState(InitialState)
    const [{ modal }, setModal] = useState(InitialState)
    const [{ landingModal }, setLandingModal] = useState(InitialState)
    const [{ landings }, setLandings] = useState(InitialState)
    const [lendingLoad, setLendingLoad] = useState(true)
    const [token, setToken] = useState(InitialState.token)
    const [currentSport, setCurrentSport] = useState(InitialState.currentSport)

    const handleSetTimeZone = (ms: number) =>
        setTimeZone((state) => ({ ...state, differanceTimeZoneInMs: ms }))
    const handleSetModal = (modal1: 'img' | 'custom' | '') => {
        setModal((state) => ({ ...state, modal: modal1 }))
    }
    const handleSetLandingModal = (modal2: 'add' | 'remove' | '') =>
        setLandingModal((state) => ({ ...state, landingModal: modal2 }))
    const handleSetLandings = (landings1: string[]) =>
        setLandings((state) => ({ ...state, landings: landings1 }))

    usePageTracking()
    const isUserLoggedInWithoutSubscription = checkUserSubscription()
    const [isOpen, setIsOpen] = useState(true)

    const closeModal = () => {
        setIsOpen(false)
    }

    // const landingsNames = [
    //   "nfl",
    //   "nhl",
    //   "nba",
    // ]

    const handleModalClose = () => {
        handleSetModal('')
        setIsOpen(true)
    }

    useEffect(() => {
        if (pathname === '/' || pathname === '/pricing') {
            window.document.body.style.backgroundImage = blackBackGround
            window.document.body.style.backgroundAttachment = 'fixed'
            window.document.body.style.backgroundPosition = '0% 0%'
            window.document.body.style.backgroundRepeat = 'repeat'
            window.document.body.style.backgroundSize = 'auto auto'
        } else if (
            pathname === '/contact_us' ||
            pathname === '/terms-and-conditions' ||
            pathname === '/privacy-policy' ||
            pathname === '/refund-policy' ||
            pathname === '/auth'
        ) {
            window.document.body.style.backgroundImage = mainBackGround
            window.document.body.style.backgroundSize = 'cover'
            window.document.body.style.backgroundRepeat = 'no-repeat'
            window.document.body.style.backgroundPosition = 'center'
            window.document.body.style.backgroundAttachment = 'fixed'
        } else {
            window.document.body.style.backgroundColor = '#031026'
        }
        setIsOpen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const currentDomain = window.location.hostname
    const shouldIndex = currentDomain === 'tazztv.me'

    return (
        <InitialStateContext.Provider
            value={{
                differanceTimeZoneInMs,
                setTimeZone: handleSetTimeZone,
                modal,
                setModal: handleSetModal,
                landingModal,
                setLandingModal: handleSetLandingModal,
                landings,
                setLandings: handleSetLandings,
                token,
                setToken,
                currentSport,
                setCurrentSport,
            }}
        >
            <Helmet>
                {!shouldIndex ? (
                    <meta name="robots" content="noindex, nofollow" />
                ) : (
                    <link
                        rel="canonical"
                        href={`https://${currentDomain}${window.location.pathname}`}
                    />
                )}
            </Helmet>
            <QueryClientProvider client={queryClient}>
                <Suspense>
                    <Routes>
                        <Route path={APP_PATHS.HOME} element={<TvPage />} />
                        <Route
                            path={APP_PATHS.SESSION_RENEW}
                            element={<SessionRenew />}
                        />
                        <Route path={APP_PATHS.FAQ} element={<FAQ />} />
                        <Route path={APP_PATHS.HELP} element={<Help />} />
                        <Route
                            path={APP_PATHS.CONTACT_US}
                            element={<ContactUs />}
                        />
                        <Route
                            path={APP_PATHS.TERMS_AND_CONDITIONS}
                            element={<TermsAndConditions />}
                        />
                        {/*<Route path="/refund-policy" element={<RefundPolicy />} />*/}
                        <Route
                            path={APP_PATHS.PRIVACY_POLICY}
                            element={<PrivacyPolicy />}
                        />
                        <Route path={APP_PATHS.PRICING} element={<Pricing />} />
                        {/*<Route*/}
                        {/*    key="boxing"*/}
                        {/*    path={APP_PATHS.BOXING}*/}
                        {/*    element={*/}
                        {/*        <BoxingLending kind={'boxing'} email={email} />*/}
                        {/*    }*/}
                        {/*/>*/}
                        {landings?.map((el) => (
                            <Route
                                key={el}
                                path={`/${el}`}
                                element={
                                    <MainLending kind={el} email={email} />
                                }
                            />
                        ))}
                        {lendingLoad ? null : (
                            <Route path="*" element={<Navigate to="/auth" />} />
                        )}
                        <Route
                            path="/crm/:pathParam"
                            element={<ExternalRedirect />}
                        />
                    </Routes>
                </Suspense>
                {modal ? (
                    <div
                        onClick={handleModalClose}
                        className={`${modal === 'img' ? 'items-center' : 'items-center xl:items-start tall:items-center'} fixed z-[100] text-white top-0 left-0 w-full h-full overflow-auto flex justify-center bg-blackOpacity`}
                    >
                        <div className="relative">
                            <div
                                className={`${modal === 'img' ? 'top-[-50px] right-0' : 'top-[-50px] right-0 xl:top-2 xl:right-2 tall:top-[-50px] tall:right-0'} cursor-pointer bg-darkBlue w-[40px] absolute h-[40px] flex items-center justify-center rounded-lg`}
                            >
                                <CloseIcon className="stroke-white h-[30px] w-[30px]" />
                            </div>
                            <div onClick={(e) => e.stopPropagation()}>
                                {modal === 'img' ? (
                                    <CustomImage
                                        width={1599}
                                        height={899}
                                        className="xl:w-[63.96vw] xl:h-[35.96vw] w-[79.95vw] h-[44.95vw]"
                                        mockImg="/images/whyZuzz.jpg"
                                    />
                                ) : (
                                    <>
                                        {/* {!isUserLoggedInWithoutSubscription ? (
                                            <TrialOverModal />
                                        ) : null} */}
                                        <PricingModal
                                            isVisible={isOpen}
                                            closeModal={closeModal}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
                {landingModal ? (
                    <Suspense>
                        <div className="w-full h-full fixed top-0 bg-blackOpacity z-[1000] flex justify-center items-center">
                            {landingModal === 'add' ? (
                                <LandingModal />
                            ) : (
                                <LandingModalRemove />
                            )}
                        </div>
                    </Suspense>
                ) : null}
            </QueryClientProvider>
        </InitialStateContext.Provider>
    )
}
