import React, {
    lazy,
    useContext,
    useEffect,
    useMemo,
    useState,
    Suspense,
} from 'react'
import { DropDownIcon } from '../../assets/icons/dropDownIcon'
import { formatDate } from '../../assets/constants/date'
import { InitialStateContext } from '../../App'
import { MapPoint } from '../../assets/icons/mapPoint'

const CustomDropDownItem = lazy(() => import('./customDropDownItem'))

interface IProps {
    options: { name: string; value: number }[]
    className: string
}

export const CustomDropDown = ({ options, className = '' }: IProps) => {
    const { setTimeZone } = useContext(InitialStateContext)
    const [open, setOpen] = useState(false)
    const gmtValue = -(+new Date().getTimezoneOffset() / 60)
    const [active, setActive] = useState<
        { name: string; value: number } | undefined
    >(options?.find((el) => el.value === gmtValue))

    const [milliseconds, setMilliseconds] = useState<number>(
        Date.now() - (gmtValue - (active?.value || 0)) * 3.6e6
    )

    useEffect(() => {
        const interval = setInterval(() => {
            setMilliseconds(
                Date.now() - (gmtValue - (active?.value || 0)) * 3.6e6
            )
        }, 1000)

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [milliseconds])

    useEffect(() => {
        setMilliseconds(Date.now() - (gmtValue - (active?.value || 0)) * 3.6e6)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    const handleSet = (elem: { name: string; value: number }) => {
        setActive(elem)
        setTimeZone((elem.value - gmtValue) * 3.6e6)
    }

    const handleBlur = () => {
        setTimeout(() => setOpen(false), 100)
    }

    return (
        <>
            <div
                tabIndex={-1}
                onBlur={handleBlur}
                onClick={() => setOpen(!open)}
                className={`z-50 outline-none ${className} relative text-white w-max text-sm justify-center flex items-center cursor-pointer bg-whiteOpacity1 py-2 px-3 lg:ml-[20px] rounded-lg gap-4`}
            >
                <MapPoint />
                <span className="whitespace-nowrap font-rubikRegular">
                    {active?.name}
                </span>
                <DropDownIcon
                    className={`${open ? 'rotate-[-90deg]' : 'rotate-90'} w-[8px] h-[12px] stroke-white `}
                />
                <Suspense>
                    <ul
                        className={`text-sm backdrop-blur absolute ${!open && 'hidden'} bg-darkBlueOpacity thinScrollbar overflow-y-scroll w-[80%] min-w-max h-max max-h-52 lg:top-12 top-7 lg:right-auto right-[-2px] z-10 rounded-lg`}
                    >
                        {useMemo(
                            () =>
                                options
                                    .filter(
                                        (filter) => filter.name !== active?.name
                                    )
                                    .map(
                                        (elem, index) =>
                                            // eslint-disable-next-line react-hooks/exhaustive-deps
                                            open && (
                                                <CustomDropDownItem
                                                    key={index}
                                                    value={elem.name}
                                                    onClick={() =>
                                                        handleSet(elem)
                                                    }
                                                />
                                            )
                                    ),
                            [options, active, open]
                        )}
                    </ul>
                </Suspense>
            </div>
            <span className="text-white text-[20px] bg-none rounded-[8px] flex items-center justify-center lg:w-[85px] lg:ml-[20px] lg:mr-[5px] h-[32px] font-rubikBold">
                {formatDate(new Date(milliseconds), 'HH:mm')}
            </span>
        </>
    )
}
