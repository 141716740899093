import React from 'react'

export const BankCardIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.30727 8C3.02414 8 2.88257 8 2.7733 7.94253C2.67943 7.89316 2.59917 7.80937 2.55389 7.71346C2.50118 7.60182 2.50704 7.46602 2.51874 7.19444C2.54861 6.50152 2.62607 6.03233 2.82698 5.63803C3.1146 5.07354 3.57354 4.6146 4.13803 4.32698C4.77976 4 5.61984 4 7.3 4H17.7C19.3802 4 20.2202 4 20.862 4.32698C21.4265 4.6146 21.8854 5.07354 22.173 5.63803C22.3739 6.03233 22.4514 6.50152 22.4813 7.19444C22.493 7.46602 22.4988 7.60182 22.4461 7.71346C22.4008 7.80937 22.3206 7.89316 22.2267 7.94253C22.1174 8 21.9759 8 21.6927 8H3.30727ZM2.5 10.8C2.5 10.52 2.5 10.38 2.5545 10.273C2.60243 10.1789 2.67892 10.1024 2.773 10.0545C2.87996 10 3.01997 10 3.3 10H21.7C21.98 10 22.12 10 22.227 10.0545C22.3211 10.1024 22.3976 10.1789 22.4455 10.273C22.5 10.38 22.5 10.52 22.5 10.8V15.2C22.5 16.8802 22.5 17.7202 22.173 18.362C21.8854 18.9265 21.4265 19.3854 20.862 19.673C20.2202 20 19.3802 20 17.7 20H7.3C5.61984 20 4.77976 20 4.13803 19.673C3.57354 19.3854 3.1146 18.9265 2.82698 18.362C2.5 17.7202 2.5 16.8802 2.5 15.2V10.8ZM7.5 14C6.94772 14 6.5 14.4477 6.5 15C6.5 15.5523 6.94772 16 7.5 16H11.5C12.0523 16 12.5 15.5523 12.5 15C12.5 14.4477 12.0523 14 11.5 14H7.5Z"
                fill="#1498A0"
            />
        </svg>
    )
}
