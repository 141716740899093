import { BaseApiService } from './baseApiService'
import { CheckSessionRequest, SessionData } from '../types/auth'
import { CRM_API_URL } from '../assets/constants/crmApi'

class BillingRepository extends BaseApiService {
    constructor() {
        super('', `${CRM_API_URL}`)
    }

    checkSession = (body?: CheckSessionRequest) => {
        const payload = body ? JSON.stringify(body) : ''
        return this.postWithCredentials<SessionData>('check-session', payload)
    }
}

export const billingRepository = new BillingRepository()
