import { SettingsIcon } from '../icons/settingsIcon'
import { HelpIcon } from '../icons/helpIcon'
import { PaymentHistoryIcon } from '../icons/paymentHistoryIcon'
import { Logout } from '../icons/logout'
import { CRM_API_URL } from './crmApi'
import { PricingIcon } from '../icons/pricingIcon'

export const headerItems = [
    {
        name: 'My Account',
        className: 'fill-white, fill-violet',
        link: `${CRM_API_URL}member`,
        icon: (className: string) => <SettingsIcon className={className} />,
    },
    {
        name: 'Help',
        className: 'stroke-white, stroke-violet',
        link: `${CRM_API_URL}helpdesk`,
        icon: (className: string) => <HelpIcon className={className} />,
    },
    {
        name: 'Payment history',
        className: 'fill-white, fill-violet',
        link: `${CRM_API_URL}member/payment-history`,
        icon: (className: string) => (
            <PaymentHistoryIcon className={className} />
        ),
    },
    {
        name: 'Pricing',
        className: 'fill-white, fill-violet',
        link: '/pricing',
        icon: (className: string) => <PricingIcon className={className} />,
    },
    {
        name: 'Sign out',
        className: 'stroke-violet',
        icon: (className: string) => <Logout className={className} />,
    },
]
