import { FC, PropsWithChildren } from 'react'
import HeaderDesktop from './components/header/headerDesktop'
import HeaderMobile from './components/header/headerMobile'
import { Footer } from './components/Footer'
import { SPORTS } from '../constant/sports'
import { Navbar } from './components/Navbar'

interface IProps {
    currentSport?: SPORTS
    isLending?: boolean
    assets?: boolean
    reviews?: number
}

const Layout: FC<PropsWithChildren<IProps>> = ({
    children,
    currentSport,
    isLending,
    assets,
    reviews,
}) => {
    return (
        <div className="min-h-full flex flex-col">
            <div
                className={
                    isLending ? 'bg-[rgba(3,16,38,1)] sticky top-0 z-[51]' : ''
                }
            >
                <header className="hidden lg:flex justify-between items-center h-[62px] xl:w-[1300px] xl:mx-auto px-3 xl:px-0">
                    <HeaderDesktop isLending={isLending} access={assets} />
                </header>
            </div>
            <div
                className={`${isLending ? 'bg-[rgba(3,16,38,1)]' : 'bg-darkBlue'} lg:bg-[transparent] posSticky top-0 bottom-0 left-0 right-0 z-[51] xl:w-[1300px] xl:mx-auto`}
            >
                <HeaderMobile isLending={isLending} access={assets} />
                {currentSport ? (
                    <div className="md:mx-3 flex relative">
                        <Navbar />
                    </div>
                ) : null}
            </div>
            <main
                className={`overflow-hidden ${isLending ? '' : 'xl:w-[1300px] md:px-3 '} relative flex flex-grow-[1] flex-col mx-auto w-full`}
            >
                {children}
            </main>
            <div className="unique:hidden md:mx-3 mt-3">
                <Footer className="bg-[transparent]" />
            </div>
        </div>
    )
}

export default Layout
