import i18n from "../../common/i18n";

export const formatDate = (date: string | Date, formatString: string) => {
  const newDate = new Date(date);
  if (newDate.getDate()) {
    return formatString
      .replace("ddd", String(newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()))
      .replace("dd", padZero(newDate.getDate()))
      .replace("DD", daysInTimer(Math.floor(newDate.getTime() / 86400000)))
      .replace("MMMM", i18n.t(`monthList.default.${newDate.getMonth()}`))
      .replace("MMM", i18n.t(`monthList.short.${newDate.getMonth()}`))
      .replace("MM", padZero(newDate.getMonth() + 1))
      .replace("yyyy", String(newDate.getFullYear()))
      .replace("HH", padZero(newDate.getHours()))
      .replace("hhh", String(padZero(Math.floor(+newDate / (1000 * 60 * 60)))))
      .replace("mm", padZero(newDate.getMinutes()))
      .replace("ss", padZero(newDate.getSeconds()))
      .replace("ww", getDayOfWeek(+padZero(newDate.getDay()), "monthList.dayOfWeek."))
      .replace("WW", getDayOfWeek(+padZero(newDate.getDay()), "monthList.dayOfWeekFull."));
  } else {
    return "invalid date";
  }
};

const daysInTimer = (n: number) => `In ${n} day${n === 1 ? "" : "s"}`;

const padZero = (n: number): string => (n < 10 ? `0${n}` : String(n));


export const getDayOfWeek = (number: number, searchStr: "monthList.dayOfWeek." | "monthList.dayOfWeekFull.") => {
  if(number === new Date().getDay()){
    return "Today";
  }
  if(number === new Date().getDay() + 1){
    return "Tomorrow"
  }
  switch (number) {
    case 0o0:
      return i18n.t(`${searchStr}6`);
    case 0o1:
      return i18n.t(`${searchStr}0`);
    case 0o2:
      return i18n.t(`${searchStr}1`);
    case 0o3:
      return i18n.t(`${searchStr}2`);
    case 0o4:
      return i18n.t(`${searchStr}3`);
    case 0o5:
      return i18n.t(`${searchStr}4`);
    case 0o6:
      return i18n.t(`${searchStr}5`);
    default:
      return "Validation Error";
  }
};
