export class BaseApiService {
    constructor(uri: string, domain?: string) {
        this.url = domain
            ? `${domain}${uri}`
            : `${process.env.REACT_APP_BACK_API}${uri}`
    }

    protected url: string

    protected timeStampUrl = (uri: string) =>
        `${this.url}${uri}${uri.includes('?') ? '&' : '?'}timestamp=${new Date().getTime()}`

    protected simpleUrl = (uri: string) => `${this.url}${uri}`
    protected otherGet = <T extends unknown>(uri: string, signal?: AbortSignal): Promise<T> =>
        fetch(this.timeStampUrl(uri), {
            method: 'GET',
            cache: 'no-store',
            signal,
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))

    protected get = <T extends unknown>(uri: string): Promise<T> =>
        fetch(this.simpleUrl(uri), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))

    protected put = <T extends unknown>(
        uri: string,
        body: string
    ): Promise<T> => {
        return fetch(this.timeStampUrl(uri), {
            method: 'PUT',
            body,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))
    }

    protected delete = <T extends unknown>(uri: string): Promise<T> => {
        return fetch(this.timeStampUrl(uri), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))
    }

    protected post = <T extends unknown>(
        uri: string,
        body?: string
    ): Promise<T> => {
        return fetch(this.timeStampUrl(uri), {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))
    }

    protected postWithCredentials = <T extends unknown>(
        uri: string,
        body: string
    ): Promise<T> => {
        return fetch(this.simpleUrl(uri), {
            method: 'POST',
            body,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))
    }

    protected otherPost = <T extends unknown>(
        uri: string,
        body: object,
        signal?: AbortSignal
    ): Promise<T> => {
        const formdata = new FormData()
        Object.entries(body).forEach(([key, value]) =>
            formdata.append(key, value)
        )
        return fetch(this.timeStampUrl(uri), {
            body: formdata,
            method: 'POST',
            redirect: 'follow',
            cache: 'no-store',
            signal,
        })
            .then((res) =>
                res.ok ? this.onSuccess(res) : this.handleError(undefined, res)
            )
            .catch((error) => this.handleError(error, undefined))
    }

    private onSuccess = (res: Response) =>
        res.status > 299 || res.status < 200
            ? this.handleError(undefined, res)
            : res
                  .json()
                  .then((value) => value)
                  .catch()

    private handleError = async (requestError?: unknown, res?: Response) => {
        // eslint-disable-next-line no-console
        console.log(res)
    }
}
