import { useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import useClickOutside from '../why/useClickOutside'
import { CloseIcon } from '../../../../../assets/icons/closeIcon'
import '../../../../../assets/css/amember.css'
import {
    paymentMethods,
    pricingPlans,
    subscriptionOptions,
} from '../../../../../constant/ameber-pricing'
import useProfileQuery from '../../../../../queries/use-profile-query'
import { AUTH_SIGNUP_URL_PRICING } from '../../../../../constant/auth'
import { usePricing } from '../../../../../hooks/usePricing'

type Props = {
    closeModal?: () => void
    isVisible: boolean
}

const PricingModal = ({ closeModal, isVisible }: Props) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const { isLoadingPricing, getProducts } = usePricing()

    const [selectedSubscription, setSelectedSubscription] =
        useState<string>('4') // Default: All Sports
    const [selectedPlan, setSelectedPlan] = useState<string>('9-9') // Default for All Sports: Monthly
    const [selectedPayment, setSelectedPayment] = useState<string>('nbcgate') // Default: Card / Crypto
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

    const { token: isLoggedIn } = useProfileQuery()

    useClickOutside(modalRef, closeModal)

    const products = getProducts(Number(selectedSubscription))

    const orderMap = useMemo(
        () =>
            new Map(
                pricingPlans[selectedSubscription].map((item, index) => [
                    item.id,
                    index,
                ])
            ),
        []
    )
    const filteredPaymentMethods = useMemo(() => {
        if (!selectedPlan || isLoadingPricing) return []

        return paymentMethods
            .filter((m) => {
                const allowedPaysys = products?.find(
                    (p) => `${p.product_id}-${p.product_id}` === selectedPlan
                )?.allowed_paysys

                if (!allowedPaysys) return false

                return allowedPaysys.some((p) => p.startsWith(m.id))
            })
            .map((m) => {
                const allowedPaysys = products?.find(
                    (p) => `${p.product_id}-${p.product_id}` === selectedPlan
                )?.allowed_paysys

                const paymentId =
                    allowedPaysys?.find((p) => p.startsWith(m.id)) || m.id

                return { ...m, id: paymentId }
            })
    }, [selectedPlan, isLoadingPricing, selectedSubscription, paymentMethods])

    // Update selected subscription and reset plan to first available for that subscription
    const handleSubscriptionChange = (id: string) => {
        if (selectedSubscription !== id) {
            setSelectedSubscription(id)

            const plans = pricingPlans[id]
            setSelectedPlan(plans[0].value)
        }

        const products = getProducts(Number(id))
        products.sort(
            (a, b) =>
                (orderMap.get(a.product_id) ?? Infinity) -
                (orderMap.get(b.product_id) ?? Infinity)
        )

        if (products?.length > 0) {
            setSelectedPayment(products?.[0]?.allowed_paysys?.[0])
        }
    }

    const currentPlans = pricingPlans[selectedSubscription] || []
    const currentPlan =
        currentPlans.find((plan) => plan.value === selectedPlan) ||
        currentPlans[0]

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (!selectedSubscription || !selectedPlan || !selectedPayment) {
            alert('Please select a subscription, plan, and payment method!')
            return
        }

        const form = document.getElementById('jsAutoForm') as HTMLFormElement
        const hiddenProd = document.getElementById(
            'jsProductHidden'
        ) as HTMLInputElement
        const hiddenPay = document.getElementById(
            'jsPaysysHidden'
        ) as HTMLInputElement
        const hiddenPageNext = document.getElementsByName(
            '_qf_page-0_next'
        )[0] as HTMLInputElement
        const hiddenSave = document.getElementsByName(
            '_save_'
        )[0] as HTMLInputElement
        const hiddenDataSaved = document.getElementsByName(
            'data-saved_form_id'
        )[0] as HTMLInputElement

        // Set the hidden field values
        hiddenProd.value = selectedPlan
        hiddenPay.value = selectedPayment
        hiddenPageNext.value = '1'
        hiddenSave.value = 'page-0'
        hiddenDataSaved.value = '1'

        // Submit the form
        form.submit()
    }

    return (
        <div
            className={classNames(
                'fixed inset-0 flex items-center justify-center z-[1000] transition-opacity duration-200',
                {
                    'opacity-100 visible': isVisible,
                    'opacity-0 invisible': !isVisible,
                }
            )}
            style={{
                background: 'rgba(10, 9, 15, 0.5)',
                backdropFilter: 'blur(10px)',
            }}
        >
            <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-darkBlue w-10 h-10 flex items-center justify-center rounded-full z-[1100]"
                aria-label="Close modal"
            >
                <CloseIcon className="stroke-white h-6 w-6" />
            </button>

            <div
                ref={modalRef}
                className="fixed inset-0 w-[811px] max-w-[99vw] h-[900px] max-h-[95vh] m-auto"
            >
                <div className="pricing-modal bodyAmember">
                    {/* Subscription Options */}
                    <div className="pricing-modal__options">
                        {subscriptionOptions.map((sub) => (
                            <div
                                key={sub.id}
                                className={classNames('pricing-option', {
                                    'pricing-option--selected':
                                        selectedSubscription === sub.id,
                                })}
                                onClick={() => handleSubscriptionChange(sub.id)}
                                data-cat-id={sub.id}
                            >
                                <h2 className="pricing-option__title">
                                    {sub.title}
                                </h2>
                                <p className="pricing-option__description">
                                    {sub.description}
                                </p>
                            </div>
                        ))}
                        <p className="pricing-option__note">
                            <span className="pricing-option__note-icon">!</span>{' '}
                            You don't have any subscription.
                        </p>
                    </div>

                    {/* Subscription Details */}
                    <section className="pricing-modal__details">
                        <header className="pricing-modal__header">
                            <h2 className="pricing-modal__heading">
                                Choose your plan
                            </h2>
                            <p className="pricing-modal__subheading">
                                Subscribe for updates and exclusive content!
                            </p>
                        </header>

                        {currentPlan && (
                            <div
                                className={classNames(
                                    'pricing__card',
                                    'pricing__card--visible'
                                )}
                                data-cat-id={selectedSubscription}
                            >
                                <h2 className="pricing__title">
                                    {currentPlan.title}
                                </h2>
                                <p className="pricing__subtitle">
                                    {currentPlan.description}
                                </p>
                                <span className="pricing__price">
                                    ${currentPlan.price}{' '}
                                    <small className="pricing__price-text">
                                        / {currentPlan.period}
                                    </small>
                                </span>
                                {/* Custom Dropdown for Plan Selection */}
                                <div className="custom-dropdown">
                                    <button
                                        className={classNames(
                                            'custom-dropdown__button',
                                            { open: dropdownOpen }
                                        )}
                                        onClick={() =>
                                            setDropdownOpen(!dropdownOpen)
                                        }
                                    >
                                        {currentPlan.title}{' '}
                                        <span className="custom-dropdown__icon"></span>
                                    </button>
                                    <ul
                                        className={classNames(
                                            'custom-dropdown__menu',
                                            { open: dropdownOpen }
                                        )}
                                    >
                                        {currentPlans.map((option) => (
                                            <li
                                                key={option.value}
                                                className={classNames(
                                                    'custom-dropdown__item',
                                                    {
                                                        selected:
                                                            option.value ===
                                                            selectedPlan,
                                                    }
                                                )}
                                                onClick={() => {
                                                    setSelectedPlan(
                                                        option.value
                                                    )
                                                    setDropdownOpen(false)
                                                }}
                                                data-prod-value={option.value}
                                            >
                                                {option.title}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {/* Features List */}
                                <ul className="pricing__features">
                                    {currentPlan.features.map((feat, idx) => (
                                        <li
                                            key={idx}
                                            className="pricing__features-item"
                                        >
                                            <img
                                                src={feat.img}
                                                alt={feat.text}
                                                height="32"
                                                width="32"
                                            />
                                            <p className="pricing__features-text">
                                                {feat.text}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                                {isLoggedIn ? (
                                    <form onSubmit={handleSubmit}>
                                        <button
                                            type="submit"
                                            className="pricing__btn"
                                        >
                                            Get Now
                                        </button>
                                    </form>
                                ) : (
                                    <a
                                        href={AUTH_SIGNUP_URL_PRICING}
                                        className="pricing__btn"
                                    >
                                        Sign up to continue
                                    </a>
                                )}
                            </div>
                        )}

                        {/* Payment Methods & Hidden PHP Form */}
                        <form
                            id="jsAutoForm"
                            action={`/crm/signup/index`}
                            method="post"
                        >
                            <input
                                type="hidden"
                                name="product_id_page-0[]"
                                id="jsProductHidden"
                                value={selectedPlan}
                            />
                            <input
                                type="hidden"
                                name="paysys_id"
                                id="jsPaysysHidden"
                                value={selectedPayment}
                            />
                            <input
                                type="hidden"
                                name="_qf_page-0_next"
                                value="1"
                            />
                            <input type="hidden" name="_save_" value="page-0" />
                            <input
                                type="hidden"
                                name="data-saved_form_id"
                                value="1"
                            />

                            {isLoggedIn ? (
                                <>
                                    <div
                                        className="modal__form"
                                        id="js-payment-methods"
                                    >
                                        {filteredPaymentMethods
                                            .filter((method) =>
                                                method.available.includes(
                                                    selectedSubscription
                                                )
                                            )
                                            .map((method) => (
                                                <label
                                                    key={method.id}
                                                    className={classNames(
                                                        'radio',
                                                        {
                                                            'radio--selected':
                                                                selectedPayment.includes(
                                                                    method.id
                                                                ),
                                                        }
                                                    )}
                                                    data-ps-id={method.id}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="payment"
                                                        value={method.id}
                                                        className="radio__input"
                                                        checked={selectedPayment.includes(
                                                            method.id
                                                        )}
                                                        onChange={() =>
                                                            setSelectedPayment(
                                                                method.id
                                                            )
                                                        }
                                                    />
                                                    <span className="radio__custom"></span>
                                                    <span className="radio__label">
                                                        {method.label}
                                                        <div className="radio__label-logos">
                                                            {method.logos
                                                                .filter(
                                                                    (logo) =>
                                                                        logo.isModal
                                                                )
                                                                .map((logo) => (
                                                                    <img
                                                                        key={
                                                                            logo.img
                                                                        }
                                                                        className="payment-logos__img"
                                                                        src={
                                                                            logo.img
                                                                        }
                                                                        alt={
                                                                            logo.alt
                                                                        }
                                                                        height="16"
                                                                    />
                                                                ))}
                                                        </div>
                                                    </span>
                                                </label>
                                            ))}
                                    </div>
                                    <div
                                        className="modal__form"
                                        id="js-no-payment-method"
                                        style={{
                                            display:
                                                filteredPaymentMethods.length
                                                    ? 'none'
                                                    : 'block',
                                        }}
                                    >
                                        No Payment Methods Available
                                    </div>
                                </>
                            ) : (
                                <div className="logos-wrapper logos-wrapper-modal">
                                    <div className="logos">
                                        <img
                                            className="logos__img"
                                            src={
                                                '/icons/amember/mastercard.svg'
                                            }
                                            alt={'mastercard'}
                                            height="24"
                                        />
                                        <img
                                            className="logos__img"
                                            src={
                                                '/icons/amember/visa-color.svg'
                                            }
                                            alt={'visa'}
                                            height="24"
                                        />
                                        <img
                                            className="logos__img"
                                            src={
                                                '/icons/amember/american-express-full.svg'
                                            }
                                            alt={'american-express'}
                                            height="24"
                                        />
                                        <img
                                            className="logos__img"
                                            src={'/icons/amember/paypal.svg'}
                                            alt={'paypal'}
                                            height="24"
                                        />
                                        <img
                                            className="logos__img"
                                            src={'/icons/amember/bitcoin.svg'}
                                            alt={'bitcoin'}
                                            height="24"
                                        />
                                        Crypto
                                    </div>

                                    <a
                                        href={AUTH_SIGNUP_URL_PRICING}
                                        className="modal__button"
                                    >
                                        Sign up to continue
                                    </a>
                                </div>
                            )}

                            {isLoggedIn ? (
                                <button
                                    className="modal__button"
                                    id="js-btn-get-started"
                                    type="submit"
                                >
                                    Get Now
                                </button>
                            ) : null}
                        </form>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default PricingModal
