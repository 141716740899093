import { useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from '../constant/queryKeys'
import { billingRepository } from '../api/billingRepository'
import { useContext, useEffect, useMemo, useCallback } from 'react'
import { InitialStateContext } from '../App'
import { JWTData, SessionData, UserData } from '../types/auth'
import { jwtDecode } from 'jwt-decode'
import { LocalStorageItems } from '../constant/localStorageItems'
import { QueryConfigs } from '../types/react-query'
import { APP_PATHS } from '../constant/appPaths'
import { useNavigate } from 'react-router-dom'

const decodeToken = (token: string | undefined): JWTData | null => {
    try {
        return token ? jwtDecode<JWTData>(token) : null
    } catch (error) {
        console.error('Failed to decode token:', error)
        return null
    }
}

const getSubscriptionStatus = (session: JWTData | null): boolean => {
    if (!session?.exp) return false
    const expirationDate = new Date(session.exp * 1000)
    return expirationDate > new Date()
}

const useProfileQuery = (options?: QueryConfigs<SessionData>) => {
    const { token, setToken: setContextToken } = useContext(InitialStateContext)
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const tokenData = useMemo(() => decodeToken(token), [token])
    const userData: UserData | undefined = useMemo(
        () => tokenData?.data,
        [tokenData]
    )

    const isValidSubscription = useMemo(
        () => getSubscriptionStatus(tokenData),
        [tokenData]
    )

    const isActiveSubscription = useMemo(() => {
        return Boolean(userData?.subscribed)
    }, [userData])

    const setToken = useCallback((newToken?: string) => {
        if (newToken) {
            setContextToken(newToken)
            localStorage.setItem(LocalStorageItems.TOKEN, newToken)
        }
    }, [])

    const clearSession = useCallback(() => {
        localStorage.removeItem(LocalStorageItems.TOKEN)
        setContextToken('')
    }, [])

    const refreshToken = useCallback(async () => {
        try {
            const response = await billingRepository.checkSession({ token })

            console.log('refreshToken response', response)

            if (response?.token) {
                setToken(response.token)
                // Invalidate profile query to refetch with the new token
                queryClient.invalidateQueries([QUERY_KEYS.profile])
            } else {
                navigate(APP_PATHS.SESSION_RENEW)
                console.error(
                    'refreshToken try else => Error refreshing token:',
                    response
                )
                // throw new Error('Failed to refresh token')
            }
        } catch (error) {
            console.error(
                'refreshToken catch => Error refreshing token:',
                error
            )
            // clearSession()
        }
    }, [token, queryClient])

    useEffect(() => {
        if (tokenData?.exp) {
            const currentTime = Date.now()
            const expirationTime = tokenData.exp * 1000
            const timeUntilExpiration = expirationTime - currentTime
            const refreshThreshold = 5 * 60 * 1000 // 5 minutes before expiration

            if (timeUntilExpiration > refreshThreshold) {
                const timer = setTimeout(() => {
                    refreshToken()
                }, timeUntilExpiration - refreshThreshold)

                return () => clearTimeout(timer)
            } else {
                refreshToken() // Attempt to refresh the token even if it has expired
            }
        }
    }, [tokenData])

    const query = useQuery<SessionData>(
        [QUERY_KEYS.profile],
        async () => {
            const payload = token ? { token } : undefined

            console.log('query payload => ', payload)

            return await billingRepository.checkSession(payload)
        },
        {
            ...options,
            retry: false,
            onError: (error) => {
                console.warn('onError => Session check failed:', error)
                if (token) {
                    navigate(APP_PATHS.SESSION_RENEW)
                }
            },
            onSuccess: (data) => {
                if (data?.token) {
                    setToken(data.token)
                } else {
                    if (token) {
                        navigate(APP_PATHS.SESSION_RENEW)
                    }
                    console.warn('onSuccess => Session check failed:', data)
                }
            },
            staleTime: 60 * 60 * 1000, // 1 hour
            cacheTime: 60 * 60 * 1000, // 1 hour
        }
    )

    return {
        ...query,
        token,
        userData,
        isValidSubscription,
        isActiveSubscription,
        setToken,
        clearSession,
    }
}

export default useProfileQuery
