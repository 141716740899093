import React, { useState } from 'react'
import classNames from 'classnames'

import WhyTableModal from './whyTableModal'
import { DistributeVerticalIcon } from '../../../../../assets/icons/distributeVerticalIcon'

const Why = () => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)

    const closeModal = () => setIsOpen(false)

    return (
        <>
            <button
                onClick={openModal}
                className={classNames(
                    'px-4 py-2 rounded font-bold flex gap-2',
                    {
                        'text-white': !isOpen,
                        'text-[#8f8d91]': isOpen,
                    }
                )}
            >
                <DistributeVerticalIcon fill={isOpen ? '#8f8d91' : 'white'} />{' '}
                Why TAZZ
            </button>
            {isOpen && <WhyTableModal closeModal={closeModal} />}
        </>
    )
}

export default Why
