import React from 'react'
import { NavLink } from 'react-router-dom'
import { footerItems } from '../../assets/constants/footerItems'
import CustomImage from '../../components/customImage/customImage'

export const Footer = ({ className }: { className?: string }) => {
    return (
        <div
            className={`w-full h-full flex-col header:flex-row rounded-t-lg unique:px-12 px-4 py-8 flex items-start justify-start ${className}`}
        >
            <div className="flex flex-col items-start gap-8" id="left-side">
                <div className="flex items-center mini:gap-8 gap-4 flex-wrap">
                    {footerItems.map((item, index) => (
                        <NavLink
                            key={index}
                            to={item.link}
                            className="text-white mini:text-[16px] text-[14px] font-poppinsRegular whitespace-nowrap cursor-pointer hover:underline"
                        >
                            {item.name}
                        </NavLink>
                    ))}
                </div>
                <div className="text-whiteOpacity mini:text-[14px] text-[12px] font-poppinsRegular uppercase">
                    COPYRIGHT © 2024 - TAZZ TV
                </div>
            </div>

            {/*<div className="flex items-center" id="right-side">*/}
            {/*    <div className="flex flex-col items-center gap-6">*/}
            {/*        <CustomImage*/}
            {/*            mockImg="/images/newLendingImg/icons/trustLogo.png"*/}
            {/*            className="mini:w-[127.45px] w-[110px]  self-start"*/}
            {/*        />*/}
            {/*        <span className="text-whiteOpacity mini:text-[14px] text-[12px]  font-poppinsRegular text-nowrap">*/}
            {/*            4.6 out of 5 based on 4389 Reviews*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
