import React from "react";
import CustomImage from "../customImage/customImage";
import type { ILink } from "../../assets/constants/linksItems";

interface IProps{
  item: ILink;
  className: string;
  onClick: () => void;
  currentSport: string;
  channel?: boolean;
  open?: boolean;
}

export const LinkItem = ({ item, className, onClick, currentSport, channel, open }: IProps) => {
  const isActive = currentSport === item.name

  return (
      <div
          onClick={onClick}
          className={`cursor-pointer relative flex items-center  h-[40px] sm:h-[50px] hover:bg-darkBlueOpacity1 ${isActive ? "bg-darkBlueOpacity1" : "" } rounded-[8px] px-[10px] ${className}`}
      >
        <div className="h-[25px] sm:h-[30px] w-[25px] sm:w-[30px] flex">
          {item.img
              ? (
                  <CustomImage
                      width={35}
                      height={35}
                      src={`uploads/${item.img}.png`}
                      className="h-[25px] sm:h-[30px] w-[25px] sm:w-[30px]"
                      alt={item.name}
                  />
              )
              : item?.svg
                  ? item.svg(`h-[20px] sm:h-[25px] w-[20px] sm:w-[25px] m-auto fill-${isActive ? "cyan" : "white"}`)
                  : ""}
        </div>
        <span className="ml-[10px] text-white text-[16px] font-poppinsRegular overflow-hidden text-ellipsis whitespace-nowrap capitalize">
      {item.name}
    </span>
        {/*<DropDownIcon className={`stroke-violet w-[10px] h-[14px] absolute ${channel ? `${open ? "rotate-[-90deg]" : "rotate-90"} right-[15px]` : "right-[10px]"}`} />*/}
      </div>
  );
}
