import React from 'react'

type Props = {
    className?: string
}

export const CheckMarkIcon = ({ className }: Props) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect x="0.75" width="24" height="24" rx="12" fill="#1498A0" />
            <path
                d="M8.79935 10.8735L11.441 13.5151L16.634 8.29993C16.8378 8.10764 17.0847 8.00781 17.3748 8.00041C17.6648 7.99302 17.9134 8.08546 18.1205 8.27774C18.3423 8.49632 18.4532 8.74324 18.4532 9.01852C18.4532 9.29379 18.3423 9.53496 18.1205 9.74203L12.1953 15.6894C11.9734 15.8965 11.72 16 11.4349 16C11.1498 16 10.9004 15.8965 10.6866 15.6894L7.35724 12.3378C7.15017 12.1455 7.04663 11.9089 7.04663 11.6279C7.04663 11.3468 7.14277 11.1028 7.33505 10.8957C7.55363 10.6886 7.80425 10.5851 8.08692 10.5851C8.36959 10.5851 8.60706 10.6813 8.79935 10.8735Z"
                fill="#F2F2F2"
            />
        </svg>
    )
}
