import React from 'react';

interface IProps{
  className: string;
  onClick?: () => void;
}

export const AddIcon = ({ className, onClick }: IProps) => {
  return (
    <svg onClick={onClick} className={`${className} select-none`} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" strokeWidth="1.5"/>
      <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};
