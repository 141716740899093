interface IProps{
  className: string;
  onClick?: () => void;
}

export const DropDownIcon = ({ className, onClick }: IProps) => (
  <svg
    onClick={onClick}
    className={`${className || "w-[8px] h-[12px]"} transform`}
    viewBox="0 0 8 12"
    fill="none"
  >
    <path d="M1.06055 10.25L4.78055 6.53C5.07245 6.23696 5.07245 5.76304 4.78055 5.47L1.06055 1.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  );
