import React, { memo } from 'react'
import { CustomDropDown } from '../../../components/customDropDown/customDropDown'
import { options } from '../../../assets/constants/mockHeaderOption'
import { Logo } from './components/logo'
import { UserName } from './components/userName'
import { LendingItems } from './components/lendingItems'
import { Why } from './components/why'
import { FaqIcon } from '../../../assets/icons/faqIcon'
import { Link } from 'react-router-dom'
import { Pricing } from './components/pricing'
import useProfileQuery from '../../../queries/use-profile-query'

interface IProps {
    isLending?: boolean
    access?: boolean
}

const HeaderDesktop = ({ isLending, access }: IProps) => {
    const { userData } = useProfileQuery()
    const userName = userData?.name || ''

    return (
        <>
            <div className="flex items-center">
                <Logo access={access} />
                {isLending ? null : (
                    <CustomDropDown options={options} className="h-full" />
                )}
            </div>
            <div className="flex items-center gap-3">
                {isLending ? <LendingItems /> : null}
                <Pricing />
                <Why />
                <Link className="flex gap-2 mr-2" to="/faq">
                    <FaqIcon />
                    <span className="font-poppinsBold text-white">FAQ</span>
                </Link>

                <UserName isLending={isLending} />
            </div>
        </>
    )
}

export default memo(HeaderDesktop)
