interface IProps{
 open: boolean;
 onClick: () => void;
  mainPage?: boolean;
}

const elem = [
  { open: "hidden top-0", close: "top-0" },
  { open: "rotate-45 top-[5px]", close: "top-[5px]" },
  { open: "rotate-[-45deg] top-[5px]", close: "top-[5px]" },
  { open: "hidden top-[10px]", close: "top-[10px]" },
];

export const BurgerIcon = ({ open, onClick, mainPage }: IProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div onClick={onClick} className="lg:hidden h-[12px] w-[16px] block relative cursor-pointer ">
    {elem.map((el, i) => (
      <span
        key={i}
        className={`${open ? el.open : el.close} ${mainPage ? "bg-white" : "bg-blue"} h-0.5 absolute w-full rounded-xl left-0 transition-all`}
      />
    ))}
  </div>
  );
