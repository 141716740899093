import React from 'react';

interface IProps{
  className: string;
}

export const Golf = ({ className }: IProps) => {
  return (
    <svg className={className}>
      <svg id="golf" viewBox="0 0 20 20">
        <path
          d="m7.4 13.4c2 .5 3.5 1.8 3.5 3.2 0 1.9-2.5 3.4-5.5 3.4s-5.5-1.5-5.5-3.4c0-1.4 1.4-2.7 3.5-3.2v1.4c-1.3.4-2.1 1.1-2.1 1.8 0 .4.4.9 1 1.3.8.5 2 .8 3.2.8 2.4 0 4.2-1.1 4.2-2.1 0-.4-.4-.9-1-1.3-.4-.2-.7-.4-1.2-.5zm-2.7-13.4 11.7 5.5-10.3 5.5v6.2h-1.4zm12.1 10.3c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1 1.3-3.1 3.1-3.1zm0 1.4c-1 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7c1 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7zm-10.7-9.6v7.6l7.6-4.2z"></path>
      </svg>
    </svg>
  );
};
