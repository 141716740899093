import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { QUERY_KEYS } from '../constant/queryKeys'
import type { PricingPlan } from '../types/pricing'

const URL = process.env.REACT_APP_TAZZ_PRICING_DATA!

export const usePricing = () => {
    const { data: pricing, isLoading: isLoadingPricing } = useQuery<
        PricingPlan[]
    >({
        queryKey: [QUERY_KEYS.pricing],
        queryFn: () => fetch(URL).then((res) => res.json()),
    })

    const getProducts = useCallback(
        (id: number) => {
            if (!pricing) return []

            return (
                pricing
                    ?.find((p) => p.id === id)
                    ?.products?.map(({ product_id, allowed_paysys }) => ({
                        product_id,
                        allowed_paysys,
                    })) || []
            )
        },
        [pricing]
    )

    return {
        pricing,
        isLoadingPricing,
        getProducts,
    }
}
