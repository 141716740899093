import { lazy, memo, useState, Suspense, useContext } from 'react'
import { BurgerIcon } from '../../../assets/icons/burgerIcon'
import { Logo } from './components/logo'
import { InitialStateContext } from '../../../App'

const HeaderMobileLazyPart = lazy(
    () => import('./components/headerMobileLazyPart')
)

interface IProps {
    isLending?: boolean
    access?: boolean
}

const HeaderMobile = ({ isLending, access }: IProps) => {
    const { currentSport, setCurrentSport } = useContext(InitialStateContext)
    const [isBurgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false)

    if (typeof window !== 'undefined') {
        window.document.body.style.overflow = isBurgerMenuOpen
            ? 'hidden'
            : 'auto'
    }

    const handleMenuClick = () => {
        setBurgerMenuOpen(!isBurgerMenuOpen)
    }

    return (
        <div className="h-16 lg:hidden">
            <div
                className={`w-full h-full flex items-center justify-center relative ${isBurgerMenuOpen ? 'border-b border-blue z-50' : ''}`}
            >
                <div className="absolute left-5 top-7">
                    <BurgerIcon
                        open={isBurgerMenuOpen}
                        onClick={handleMenuClick}
                    />
                </div>
                <Logo access={access} />
                <Suspense>
                    <HeaderMobileLazyPart
                        currentSport={currentSport}
                        handleSetSport={setCurrentSport}
                        open={isBurgerMenuOpen}
                        setBurgerMenuOpen={setBurgerMenuOpen}
                        isLending={isLending}
                    />
                </Suspense>
            </div>
        </div>
    )
}

export default memo(HeaderMobile)
