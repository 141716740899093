// Subscription options data
export const subscriptionOptions = [
    {
        id: '4',
        title: 'All Sports',
        description:
            'Access to Soccer, Tennis, NFL, MLB, NHL, NBA and other events. Boxing and MMA PPV included',
    },
    {
        id: '2',
        title: 'US Sport',
        description:
            'Full Access to the NFL, NHL, NBA, MLB, NCAAF and NCAAB. PayPal available!',
    },
]

// Common features for each plan
export const commonFeatures = [
    {
        img: '/icons/amember/unlimited.svg',
        text: 'Unlimited Access',
    },
    {
        img: '/icons/amember/4k.svg',
        text: 'HD Streaming',
    },
    {
        img: '/icons/amember/no-blackouts.svg',
        text: 'No Blackouts',
    },
    {
        img: '/icons/amember/all-platforms.svg',
        text: 'All Platforms',
    },
]

// Pricing plans mapped to subscription IDs
export const pricingPlans: {
    [key: string]: Array<{
        id: number
        value: string
        title: string
        description: string
        price: string
        period: string
        features: typeof commonFeatures
    }>
} = {
    '4': [
        {
            id: 9,
            value: '9-9',
            title: 'Monthly Subscription',
            description:
                'Get 30 days of access to NFL, MLB, NHL, NBA, Soccer, Tennis, Boxing, MMA, F1, Golf, Rugby and other events. Instant activation, NO Blackouts!',
            price: '20.00',
            period: '30 days',
            features: commonFeatures,
        },
        {
            id: 6,
            value: '6-6',
            title: 'Annual Subscription',
            description:
                'Get 365 days of access to NFL, MLB, NHL, NBA, Soccer, Tennis, Boxing, MMA, F1, Golf, Rugby and other events. Instant activation, NO Blackouts!',
            price: '99.99',
            period: 'annual',
            features: commonFeatures,
        },
        {
            id: 12,
            value: '12-12',
            title: 'Day Pass',
            description:
                'Get 24 hours of unlimited access to all sports available on TazzTV, including UFC PPV events!',
            price: '14.99',
            period: '24 hours',
            features: commonFeatures,
        },
    ],
    '2': [
        {
            id: 20,
            value: '20-20',
            title: 'US Sport - Monthly',
            description:
                'Get 30 days of full access to the NFL, NHL, NBA, MLB, NCAAF and NCAAB. Play-offs included. Instant activation, NO Blackouts!',
            price: '20.00',
            period: '30 days',
            features: commonFeatures,
        },
        {
            id: 18,
            value: '18-18',
            title: 'US Sport - Annual',
            description:
                'Get 365 days of full access to the NFL, NHL, NBA, MLB, NCAAF and NCAAB. Play-offs included. Instant activation, NO Blackouts!',
            price: '79.99',
            period: 'annual',
            features: commonFeatures,
        },
        {
            id: 23,
            value: '23-23',
            title: 'US Sport - 7 Days',
            description:
                'Get 7 days of full access to the NFL, NHL, NBA, MLB, NCAAF and NCAAB. Play-offs included. Instant activation, NO Blackouts!',
            price: '14.99',
            period: '7 days',
            features: commonFeatures,
        },
    ],
}

// Payment methods with available subscriptions
export const paymentMethods = [
    {
        id: 'paypal',
        label: 'PayPal',
        logos: [
            {
                img: '/icons/amember/paypal.svg',
                alt: 'PayPal',
                isModal: true,
            },
            {
                img: '/icons/amember/paypal-white.svg',
                alt: 'PayPal',
                isModal: false,
            },
        ],
        available: ['4', '2'],
    },
    {
        id: 'nbcgate',
        label: 'Card / Crypto payment',
        logos: [
            {
                img: '/icons/amember/mastercard.svg',
                alt: 'Mastercard',
                isModal: true,
            },
            {
                img: '/icons/amember/visa-color.svg',
                alt: 'Visa',
                isModal: true,
            },
            {
                img: '/icons/amember/american-express.svg',
                alt: 'American Express',
                isModal: true,
            },
            {
                img: '/icons/amember/mastercard.svg',
                alt: 'Mastercard',
                isModal: false,
            },
            {
                img: '/icons/amember/visa-white.svg',
                alt: 'Visa',
                isModal: false,
            },
            {
                img: '/icons/amember/american-express.svg',
                alt: 'American Express',
                isModal: false,
            },
        ],
        available: ['4', '2'],
    },
]
