import { CRM_API_URL } from "./crmApi";

export const footerItems = [
  {name: "Terms And Conditions", link: "/terms-and-conditions"},
  {name: "Privacy Policy", link: "/privacy-policy"},
  // {name: "Refund Policy", link: "/refund-policy"},
  {name: "Affiliates", link: `${CRM_API_URL}aff/signup`},
  {name: "Support", link: `${CRM_API_URL}helpdesk`}
  // {name: "Contact Us", link: "/contact_us"},
];
