import React from 'react'

export const FaqIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.7669 9.86561C10.245 10.1421 10.8567 9.97879 11.1332 9.50073C12.0902 8.1154 13.9401 9.96 12.4234 10.8616C12.1508 11.0237 11.7943 11.2935 11.5231 11.6239C11.2663 11.9368 11.0305 12.3672 11.0028 12.896C10.9992 12.9302 10.9974 12.9649 10.9974 13C10.9974 13.5523 11.4452 14 11.9974 14C12.7597 14 12.9048 13.595 13.0512 13.1864C13.1454 12.9235 13.2401 12.6591 13.5001 12.5C14.3267 11.994 15 11.0977 15.0001 10C15.0001 8.34315 13.6569 7 12.0001 7C10.8884 7 9.91922 7.60518 9.40202 8.49927C9.12547 8.97733 9.28884 9.58906 9.7669 9.86561ZM10.9974 16C10.9974 15.4477 11.4452 15 11.9974 15C12.5497 15 12.9974 15.4477 12.9974 16C12.9974 16.5523 12.5497 17 11.9974 17C11.4452 17 10.9974 16.5523 10.9974 16Z"
                fill="white"
            />
        </svg>
    )
}
