import { jwtDecode } from 'jwt-decode'
import { LocalStorageItems } from '../constant/localStorageItems'
import { JWTData, UserData } from '../types/auth'

const decodeToken = (token?: string): JWTData | null => {
    try {
        return token ? jwtDecode<JWTData>(token) : null
    } catch (error) {
        console.error('Failed to decode token:', error)
        return null
    }
}

const getSubscriptionStatus = (session: JWTData | null): boolean => {
    if (!session?.exp) return false
    return new Date(session.exp * 1000) > new Date()
}

export const checkUserSubscription = (): boolean => {
    const token = localStorage.getItem(LocalStorageItems.TOKEN)
    if (!token) return false

    const tokenData = decodeToken(token)
    const userData: UserData | undefined = tokenData?.data
    const isValidSubscription = getSubscriptionStatus(tokenData)
    const isActiveSubscription = Boolean(userData?.subscribed)

    return isValidSubscription && !isActiveSubscription
}
