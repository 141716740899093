import React from 'react';

export const Rugby = ({ className }: {className: string}) => (
  <svg className={className}>
    <svg viewBox="0 0 20 20">
      <path
        d="m1.4 0v13.1h17.2v-13.1h1.4v20h-1.4v-5.5h-17.2v5.5h-1.4v-20zm4.2 8.6c-1.4-4.9 3.5-9.7 8.4-8.4l.3.1.1.3c1.4 4.9-3.4 9.8-8.4 8.5l-.3-.1zm1.5-3.5c-.4.9-.4 1.8-.3 2.7.4.1.9.1 1.4.1s.9-.1 1.4-.3zm2.1-2.7c-.6.4-1.1.9-1.5 1.4l3.1 3.1c.6-.4 1.1-.9 1.5-1.5zm4-.9c-.4-.1-.9-.1-1.4-.1s-.9.1-1.4.3l2.6 2.5c.2-.4.3-.9.3-1.4 0-.4 0-.9-.1-1.3z"></path>
    </svg>
  </svg>
);
