export const EuroLeague = ({ className }: { className: string }) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.000000 126.000000"
        preserveAspectRatio="xMidYMid meet"
        className={className}
    >
        <g
            transform="translate(0.000000,126.000000) scale(0.100000,-0.100000)"
            fill="#FB5400"
            stroke="none"
        >
            <path
                d="M580 1244 c-141 -24 -238 -63 -330 -132 -126 -96 -143 -188 -50 -269
53 -46 298 -153 433 -189 37 -10 67 -21 67 -25 0 -4 -24 -13 -52 -20 -110 -26
-341 -120 -419 -169 -94 -61 -114 -129 -62 -208 56 -82 202 -171 333 -203 71
-17 229 -17 300 0 183 44 345 170 425 330 33 67 26 73 -66 55 -144 -27 -287
-128 -349 -246 -23 -44 -29 -49 -76 -59 -68 -15 -158 -8 -184 16 -71 64 -5
179 168 290 134 85 283 141 467 174 100 17 100 17 100 46 0 29 -1 29 -80 42
-238 37 -539 175 -635 291 -60 73 -58 151 5 180 33 15 113 15 171 1 39 -10 48
-18 69 -58 68 -133 218 -233 388 -257 56 -8 57 -3 21 69 -80 156 -242 284
-417 326 -54 13 -188 22 -227 15z"
            />
            <path
                d="M34 797 c-25 -83 -25 -257 0 -341 l18 -58 18 38 c31 70 106 119 277
185 27 10 26 11 -50 41 -109 42 -197 103 -223 155 l-21 42 -19 -62z"
            />
        </g>
    </svg>
)
