export const APP_PATHS = {
    HOME: '/',
    SESSION_RENEW: '/session_renew',
    FAQ: '/faq',
    HELP: '/help',
    PRICING: '/pricing',
    CONTACT_US: '/contact_us',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    BOXING: '/boxing',
}
