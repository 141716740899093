export const Tennis = ({ className }: {className: string}) => {
    return (
        <svg
            className={className}
            viewBox="0 0 19.9 19.9"
        >
            <path d="m13.2.1c2-.3 3.9.3 5.1 1.6 1.3 1.3 1.8 3.1 1.6 5.1-.3 2-1.3 3.9-2.8 5.5-.8.8-1.6 1.4-2.6 1.9-.8.4-1.7.7-2.6.9l-7.6 1.6-3.3 3.2h-1v-1l3.3-3.3 1.6-7.6c.3-1.9 1.3-3.7 2.8-5.2 1.5-1.5 3.5-2.5 5.5-2.7zm-7.8 12.1-.6 2.9 2.9-.6c-1-.5-1.8-1.3-2.3-2.3zm8.7-10.8c-.3 0-.5 0-.8.1-1.6.2-3.3 1-4.7 2.4-1.2 1.2-2 2.6-2.3 4.1l-.1.3v.2c-.2 1.6.2 3 1.2 4s2.4 1.4 4 1.2h.2l.4-.1c1.5-.3 2.9-1.1 4.1-2.3.7-.7 1.2-1.4 1.6-2.2s.7-1.6.8-2.5c.2-1.6-.2-3-1.2-4-.8-.8-1.9-1.2-3.2-1.2zm-11.4-1.4c.7 0 1.4.3 1.9.8.6.5.9 1.2.9 1.9 0 1.5-1.2 2.7-2.7 2.7s-2.8-1.1-2.8-2.7c0-1.5 1.2-2.7 2.7-2.7zm0 1.4c-.7 0-1.3.6-1.3 1.3 0 .8.6 1.4 1.4 1.4.4 0 .7-.1 1-.4s.4-.6.4-1c-.1-.7-.7-1.3-1.5-1.3z"/>
        </svg>
    );
};
