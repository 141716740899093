export const headerLendingRoutesArr = [
  "Watch",
  "Pricing",
  "Best channel",
  "Splitview",
  "No Blackouts",
  "Your terms",
  "Sports",
  "Why ZUZZ",
  "FAQ",
];
