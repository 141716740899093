export const MapPoint = () => {
    return (
        <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.11035C0 3.69207 3.58172 0.110352 8 0.110352C12.4183 0.110352 16 3.69207 16 8.11035C16 10.8005 14.3913 13.0808 12.5377 14.6872L9.47869 17.3384C8.63007 18.0739 7.36993 18.0739 6.52131 17.3384L3.46227 14.6872C1.60868 13.0808 0 10.8005 0 8.11035ZM8 9.88813C8.98184 9.88813 9.77778 9.09219 9.77778 8.11035C9.77778 7.12851 8.98184 6.33257 8 6.33257C7.01816 6.33257 6.22222 7.12851 6.22222 8.11035C6.22222 9.09219 7.01816 9.88813 8 9.88813Z"
                fill="white"
            />
        </svg>
    )
}

export default MapPoint
