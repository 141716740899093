import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'
import '../../assets/css/amember.css'

import {
    paymentMethods,
    pricingPlans,
    subscriptionOptions,
} from '../../constant/ameber-pricing'
import {
    AUTH_LOGIN_URL_PRICING,
    AUTH_SIGNUP_URL_PRICING,
} from '../../constant/auth'
import { usePricing } from '../../hooks/usePricing'
import useProfileQuery from '../../queries/use-profile-query'
import Layout from '../../layout/layout'

const defaultSub = subscriptionOptions[0]
const defaultPlan = pricingPlans[defaultSub.id][0]

const PricingPage = () => {
    const { isLoadingPricing, getProducts } = usePricing()
    const { token: isLoggedIn, refetch } = useProfileQuery()
    // const isLoggedIn = true

    // Global selections
    const [selectedSubscription, setSelectedSubscription] = useState<string>(
        defaultSub.id
    )
    const [selectedPlan, setSelectedPlan] = useState<string>(defaultPlan.value)
    const [selectedPayment, setSelectedPayment] = useState<string>('')

    // Per–subscription dropdown open state map
    const [dropdownOpenMap, setDropdownOpenMap] = useState<{
        [id: string]: boolean
    }>({})
    // Mobile modal state
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false)

    const orderMap = useMemo(
        () =>
            new Map(
                pricingPlans[selectedSubscription].map((item, index) => [
                    item.id,
                    index,
                ])
            ),
        []
    )

    useEffect(() => {
        if (!isLoadingPricing) {
            const products = getProducts(Number(defaultSub.id))
            products.sort(
                (a, b) =>
                    (orderMap.get(a.product_id) ?? Infinity) -
                    (orderMap.get(b.product_id) ?? Infinity)
            )

            if (products?.length > 0) {
                setSelectedPayment(products?.[0]?.allowed_paysys?.[0])
            }
        }
    }, [isLoadingPricing])

    const updateSubscriptionDetails = (id: string) => {
        if (selectedSubscription !== id) {
            setSelectedSubscription(id)

            const plans = pricingPlans[id]
            setSelectedPlan(plans[0].value)
        }

        // Close dropdown for that subscription.
        setDropdownOpenMap((prev) => {
            const newMap = { ...prev }
            Object.keys(prev).forEach((key) => {
                newMap[key] = false
            })
            return newMap
        })

        const products = getProducts(Number(id))

        products.sort(
            (a, b) =>
                (orderMap.get(a.product_id) ?? Infinity) -
                (orderMap.get(b.product_id) ?? Infinity)
        )

        if (products?.length > 0) {
            setSelectedPayment(products?.[0]?.allowed_paysys?.[0])
        }
    }

    const currentPlans = pricingPlans[selectedSubscription] || []
    const currentPlan =
        currentPlans.find((plan) => plan.value === selectedPlan) ||
        currentPlans[0]

    const filteredPaymentMethods = useMemo(() => {
        if (!selectedPlan || isLoadingPricing) return []

        const products = getProducts(Number(selectedSubscription))

        return paymentMethods
            .filter((m) => {
                const allowedPaysys = products?.find(
                    (p) => `${p.product_id}-${p.product_id}` === selectedPlan
                )?.allowed_paysys

                if (!allowedPaysys) return false

                return allowedPaysys.some((p) => p.startsWith(m.id))
            })
            .map((m) => {
                const allowedPaysys = products?.find(
                    (p) => `${p.product_id}-${p.product_id}` === selectedPlan
                )?.allowed_paysys

                const paymentId =
                    allowedPaysys?.find((p) => p.startsWith(m.id)) || m.id

                return { ...m, id: paymentId }
            })
    }, [selectedPlan, isLoadingPricing, selectedSubscription, paymentMethods])

    const doSubmit = () => {
        if (!selectedSubscription || !selectedPlan || !selectedPayment) {
            alert('Please select a subscription, plan, and payment method!')
            return
        }
        const form = document.getElementById('jsAutoForm') as HTMLFormElement
        if (form) {
            ;(
                document.getElementById('jsProductHidden') as HTMLInputElement
            ).value = selectedPlan
            ;(
                document.getElementById('jsPaysysHidden') as HTMLInputElement
            ).value = selectedPayment
            form.submit()
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (window.innerWidth > 992) {
            doSubmit()
        } else {
            setIsPaymentModalOpen(true)
        }
    }

    const handleModalSubmit = () => {
        setIsPaymentModalOpen(false)
        doSubmit()
    }

    const renewSession = async () => {
        try {
            const session = await refetch()
            if (session?.data?.code === 404) {
                window.open(AUTH_LOGIN_URL_PRICING, '_self')
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            renewSession()
        }
    }, [])

    const handleOptionClick = useCallback(
        (option: any, sub: any, e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation()
            setSelectedSubscription(sub.id)

            if (option.value !== selectedPlan) {
                setSelectedPlan(option.value)

                const products = getProducts(Number(sub.id))

                const allowedPaysys = products?.find(
                    (p) => p.product_id === option.id
                )?.allowed_paysys!

                const selectedPaymentId = allowedPaysys.find((a) =>
                    a.startsWith(selectedPayment)
                )

                const newPayment = option.paysys
                    ? option.paysys
                    : selectedPaymentId

                setSelectedPayment(newPayment)
            }

            // Close dropdown for this subscription.
            setDropdownOpenMap((prev) => ({
                ...prev,
                [sub.id]: false,
            }))
        },
        [selectedPlan, selectedPayment]
    )

    return (
        <Layout>
            <main className="pricing-page bodyAmember">
                <h1 className="pricing-page__title">Choose your plan</h1>
                <section className="pricing swiper">
                    <Swiper
                        slidesPerView={1.2}
                        spaceBetween={16}
                        pagination={{
                            el: '.swiper-pagination',
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        breakpoints={{
                            640: {
                                slidesPerView: 'auto',
                            },
                        }}
                    >
                        {subscriptionOptions.map((sub) => {
                            const current =
                                sub.id === selectedSubscription
                                    ? currentPlan
                                    : pricingPlans[sub.id][0]
                            const isDropdownOpen =
                                dropdownOpenMap[sub.id] || false
                            return (
                                <SwiperSlide key={sub.id}>
                                    <div
                                        className={classNames('pricing__card', {
                                            'pricing__card--selected':
                                                selectedSubscription === sub.id,
                                        })}
                                        data-cat-id={sub.id}
                                        onClick={() =>
                                            updateSubscriptionDetails(sub.id)
                                        }
                                    >
                                        <h2 className="pricing__title">
                                            {current.title}
                                            <div className="pricing__dot"></div>
                                        </h2>
                                        <p className="pricing__subtitle">
                                            {current.description}
                                        </p>
                                        <span className="pricing__price">
                                            ${current.price}
                                            <small className="pricing__price-text">
                                                {' / '}
                                                {current.period}
                                            </small>
                                        </span>
                                        {/* Custom Dropdown for Plan Selection */}
                                        <div className="custom-dropdown">
                                            <button
                                                className={classNames(
                                                    'custom-dropdown__button',
                                                    { open: isDropdownOpen }
                                                )}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setDropdownOpenMap(
                                                        (prev) => ({
                                                            ...prev,
                                                            [sub.id]:
                                                                !prev[sub.id],
                                                        })
                                                    )
                                                }}
                                            >
                                                {current.title}
                                                <span className="custom-dropdown__icon"></span>
                                            </button>
                                            <ul
                                                className={classNames(
                                                    'custom-dropdown__menu',
                                                    { open: isDropdownOpen }
                                                )}
                                            >
                                                {pricingPlans[sub.id].map(
                                                    (option) => (
                                                        <li
                                                            key={option.value}
                                                            className={classNames(
                                                                'custom-dropdown__item',
                                                                {
                                                                    selected:
                                                                        option.value ===
                                                                        selectedPlan,
                                                                }
                                                            )}
                                                            onClick={(e) =>
                                                                handleOptionClick(
                                                                    option,
                                                                    sub,
                                                                    e
                                                                )
                                                            }
                                                            data-prod-value={
                                                                option.value
                                                            }
                                                            data-prod-paysys={
                                                                (option as any)
                                                                    .paysys
                                                            }
                                                        >
                                                            {option.title}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                        {/* Features List */}
                                        <ul className="pricing__features">
                                            {current.features.map(
                                                (feat, idx) => (
                                                    <li
                                                        key={idx}
                                                        className="pricing__features-item"
                                                    >
                                                        <img
                                                            src={feat.img}
                                                            alt={feat.text}
                                                            height="32"
                                                            width="32"
                                                        />
                                                        <p className="pricing__features-text">
                                                            {feat.text}
                                                        </p>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                        {isLoggedIn ? (
                                            <form onSubmit={handleSubmit}>
                                                <button
                                                    type="submit"
                                                    className="pricing__btn"
                                                >
                                                    Get Started
                                                </button>
                                            </form>
                                        ) : (
                                            <a
                                                href={AUTH_SIGNUP_URL_PRICING}
                                                className="pricing__btn"
                                            >
                                                Sign up to continue
                                            </a>
                                        )}
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </section>

                {/* Payment (Desktop) */}
                {isLoggedIn ? (
                    !isLoadingPricing && (
                        <section className="payment-system">
                            <form
                                className="payment-system__form"
                                onSubmit={handleSubmit}
                            >
                                {filteredPaymentMethods.map((method) => (
                                    <label
                                        key={method?.id}
                                        className="radio"
                                        data-ps-id={method.id}
                                    >
                                        <input
                                            type="radio"
                                            name="payment"
                                            value={method.id}
                                            className="radio__input"
                                            checked={selectedPayment.includes(
                                                method.id
                                            )}
                                            onChange={() =>
                                                setSelectedPayment(method.id)
                                            }
                                        />
                                        <span className="radio__custom"></span>
                                        <span className="radio__label">
                                            {method.label}
                                            <div className="radio__label-logos">
                                                {method.logos
                                                    .filter(
                                                        (logo) => !logo.isModal
                                                    )
                                                    .map((logo) => (
                                                        <img
                                                            key={logo.img}
                                                            className="payment-logos__img"
                                                            src={logo.img}
                                                            alt={logo.alt}
                                                            height="16"
                                                        />
                                                    ))}
                                            </div>
                                        </span>
                                    </label>
                                ))}
                                <div
                                    className="radio"
                                    id="js-no-payment-desktop"
                                    style={{
                                        display: filteredPaymentMethods.length
                                            ? 'none'
                                            : 'block',
                                    }}
                                >
                                    No Payment Methods Available
                                </div>

                                <button
                                    type="submit"
                                    className="payment-system__button"
                                >
                                    Get Started
                                </button>
                            </form>
                        </section>
                    )
                ) : (
                    <div className="logos-wrapper">
                        <div className="logos">
                            <img
                                className="logos__img"
                                src={'/icons/amember/mastercard.svg'}
                                alt={'mastercard'}
                                height="24"
                            />
                            <img
                                className="logos__img"
                                src={'/icons/amember/visa-white.svg'}
                                alt={'visa'}
                                height="24"
                            />
                            <img
                                className="logos__img"
                                src={'/icons/amember/american-express-full.svg'}
                                alt={'american-express'}
                                height="24"
                            />
                            <img
                                className="logos__img"
                                src={'/icons/amember/paypal-white.svg'}
                                alt={'paypal'}
                                height="24"
                            />
                            <img
                                className="logos__img"
                                src={'/icons/amember/bitcoin.svg'}
                                alt={'bitcoin'}
                                height="24"
                            />
                            Crypto
                        </div>

                        <a
                            href={AUTH_SIGNUP_URL_PRICING}
                            className="payment-system__button"
                        >
                            Sign up to continue
                        </a>
                    </div>
                )}

                <footer className="footer">
                    <div className="footer__info">
                        <div className="footer__links">
                            <a
                                className="footer__link"
                                href="/terms-and-conditions"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms & Conditions
                            </a>
                            <a
                                className="footer__link"
                                href="/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                            <a
                                className="footer__link"
                                href="/crm/aff/aff"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Affiliates
                            </a>
                        </div>
                        <p className="footer__copyright">
                            COPYRIGHT © 2019-2025 - TAZZ TV
                        </p>
                    </div>
                    <div className="footer__trust">
                        <img
                            className="footer__trust-logo"
                            src="/icons/amember/trustpilot.png"
                            alt="Trustpilot Logo"
                            height="32"
                            width="128"
                        />
                        <span className="footer__trust-text">
                            4.6 out of 5 based on 4389 Reviews
                        </span>
                    </div>
                </footer>

                {/* Hidden PHP Form */}
                <form
                    id="jsAutoForm"
                    action="/crm/signup/index"
                    method="post"
                    style={{ display: 'none' }}
                >
                    <input
                        type="hidden"
                        name="product_id_page-0[]"
                        id="jsProductHidden"
                        value={selectedPlan}
                    />
                    <input
                        type="hidden"
                        name="paysys_id"
                        id="jsPaysysHidden"
                        value={selectedPayment}
                    />
                    <input type="hidden" name="_qf_page-0_next" value="1" />
                    <input type="hidden" name="_save_" value="page-0" />
                    <input type="hidden" name="data-saved_form_id" value="1" />
                </form>

                {/* Mobile Payment Modal */}
                {isPaymentModalOpen && (
                    <div id="payment-modal" className="modal show">
                        <div className="modal__content">
                            <button
                                className="modal__close"
                                aria-label="Close"
                                onClick={() => setIsPaymentModalOpen(false)}
                            >
                                <img
                                    className="modal__close-img"
                                    src="/icons/amember/close.svg"
                                    height="24"
                                    width="24"
                                    alt="close"
                                />
                            </button>
                            <h3 className="modal__title">Choose a Payment</h3>
                            <form
                                className="modal__form"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleModalSubmit()
                                }}
                            >
                                {filteredPaymentMethods.map((method) => (
                                    <label
                                        key={method.id}
                                        className={classNames('radio', {
                                            'radio--selected':
                                                selectedPayment === method.id,
                                        })}
                                        data-ps-id={method.id}
                                    >
                                        <input
                                            type="radio"
                                            name="payment"
                                            value={method.id}
                                            className="radio__input"
                                            checked={
                                                selectedPayment === method.id
                                            }
                                            onChange={() =>
                                                setSelectedPayment(method.id)
                                            }
                                        />
                                        <span className="radio__custom"></span>
                                        <span className="radio__label">
                                            {method.label}
                                            <div className="radio__label-logos">
                                                {method.logos
                                                    .filter(
                                                        (logo) => logo.isModal
                                                    )
                                                    .map((logo) => (
                                                        <img
                                                            key={logo.img}
                                                            className="payment-logos__img"
                                                            src={logo.img}
                                                            alt={logo.alt}
                                                            height="16"
                                                        />
                                                    ))}
                                            </div>
                                        </span>
                                    </label>
                                ))}
                                <div
                                    className="radio"
                                    id="js-no-payment-mobile"
                                    style={{
                                        display: paymentMethods.length
                                            ? 'none'
                                            : 'block',
                                    }}
                                >
                                    No Payment Methods Available
                                </div>

                                {isLoggedIn ? (
                                    <button
                                        className="modal__button"
                                        type="button"
                                        onClick={handleModalSubmit}
                                    >
                                        Get Started
                                    </button>
                                ) : (
                                    <a
                                        href={AUTH_SIGNUP_URL_PRICING}
                                        className="modal__button"
                                    >
                                        Sign up to continue
                                    </a>
                                )}
                            </form>
                        </div>
                    </div>
                )}
            </main>
        </Layout>
    )
}

export default PricingPage
