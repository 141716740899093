import { useEffect } from 'react'

// NOTE: Might want to move to an utils directory
export default function useClickOutside(
    ref: React.MutableRefObject<HTMLDivElement | null>,
    callback?: Function
) {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node) && callback) {
                callback()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, callback])
}
