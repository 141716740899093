import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { PricingIcon } from '../../../../../assets/icons/pricingIcon'

const Pricing = () => {
    return (
        <>
            <NavLink
                to={`/pricing`}
                className={classNames(
                    'py-2 rounded font-bold flex gap-2 text-white items-center'
                )}
            >
                <PricingIcon
                    className={
                        'fill-white w-[20px] md:w-[24px] h-[20px] md:h-[24px]'
                    }
                />{' '}
                Pricing
            </NavLink>
        </>
    )
}

export default Pricing
