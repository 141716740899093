import useProfileQuery from '../../../../../queries/use-profile-query'
import { BankCardIcon } from '../../../../../assets/icons/bankCardIcon'
import { CheckMarkIcon } from '../../../../../assets/icons/checkMarkIcon'
import { CoinDollarIcon } from '../../../../../assets/icons/coinDollarIcon'
import { CrossAltIcon } from '../../../../../assets/icons/crossAltIcon'
import { CRM_API_URL } from '../../../../../assets/constants/crmApi'
import { AUTH_SIGNUP_URL_PRICING } from '../../../../../constant/auth'

const StyledCheckMarkIcon = () => <CheckMarkIcon className="mx-auto" />
const StyledCrossAltIcon = () => <CrossAltIcon className="mx-auto" />

export default function useTableContent() {
    const { userData } = useProfileQuery()
    const isSubscribed = userData?.subscribed

    const header = [
        {
            text: '',
            font: '',
        },
        {
            text: 'TAZZ.TV',
            font: 'DM Serif Text',
        },
        {
            text: 'Free Streams',
            font: 'Poppins',
        },
        {
            text: 'Official',
            font: 'Poppins',
        },
    ]

    const data = [
        {
            firstColumn: 'Cost',
            current: '$99.99/season',
            free: 'Free with Ads & Malware',
            official: '$1250+/season',
        },
        {
            firstColumn: 'Live stream',
            current: <StyledCheckMarkIcon />,
            free: 'Yes',
            official: <StyledCrossAltIcon />,
        },
        {
            firstColumn: 'Quality',
            current: '240p-1080p',
            free: 'Varies',
            official: 'Your choice',
        },
        {
            firstColumn: 'Reliability',
            current: 'Great',
            free: 'Bad',
            official: 'Great',
        },
        {
            firstColumn: 'No flash required',
            current: <StyledCheckMarkIcon />,
            free: <StyledCrossAltIcon />,
            official: <StyledCrossAltIcon />,
        },
        {
            firstColumn: 'Split-view feature',
            current: <StyledCheckMarkIcon />,
            free: <StyledCrossAltIcon />,
            official: <StyledCrossAltIcon />,
        },
        {
            firstColumn: 'No blackouts',
            current: <StyledCheckMarkIcon />,
            free: <StyledCrossAltIcon />,
            official: <StyledCrossAltIcon />,
        },
        {
            firstColumn: 'Feeds',
            current: 'All official',
            free: 'Varies',
            official: 'All official',
        },
        {
            firstColumn: 'Channels',
            current: '50+',
            free: <StyledCheckMarkIcon />,
            official: 'None',
        },
        {
            firstColumn: 'Chromecast, Kodi, Plex',
            current: <StyledCheckMarkIcon />,
            free: <StyledCrossAltIcon />,
            official: <StyledCrossAltIcon />,
        },
    ]

    const footer = [
        {
            content: null,
            id: 1,
        },
        {
            content: !isSubscribed && (
                <a href={AUTH_SIGNUP_URL_PRICING}>
                    <button className="w-full text-black bg-white rounded-lg h-[38px] mt-4 flex justify-center items-center gap-2 font-bold">
                        <BankCardIcon /> <span>Subscribe</span>
                    </button>
                </a>
            ),
            id: 2,
        },
        {
            content: null,
            id: 3,
        },
        {
            content: (
                <div className="w-full text-white bg-whiteOpacity1 rounded-lg h-[38px] mt-4 flex justify-center items-center backdrop-blur-[20px]">
                    Loosing&nbsp;
                    <CoinDollarIcon />
                    &nbsp;1150 every season
                </div>
            ),
            id: 4,
        },
    ]

    return { header, data, footer }
}
