export const ChannelsIcon = ({ className }: {className: string}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  className={className}>
            <path
                d="M15.5681 3C15.3157 2.99997 15.0699 2.99994 14.8618 3.01695C14.6332 3.03563 14.3634 3.07969 14.092 3.21799C13.7157 3.40974 13.4097 3.7157 13.218 4.09202C13.0797 4.36345 13.0356 4.63318 13.017 4.86178C12.9999 5.06993 13 5.31572 13 5.56811V10.4319C13 10.6843 12.9999 10.9301 13.017 11.1382C13.0356 11.3668 13.0797 11.6366 13.218 11.908C13.4097 12.2843 13.7157 12.5903 14.092 12.782C14.3634 12.9203 14.6332 12.9644 14.8618 12.9831C15.0699 13.0001 15.3157 13 15.5681 13H18.4319C18.6843 13 18.9301 13.0001 19.1382 12.9831C19.3668 12.9644 19.6366 12.9203 19.908 12.782C20.2843 12.5903 20.5903 12.2843 20.782 11.908C20.9203 11.6366 20.9644 11.3668 20.9831 11.1382C21.0001 10.9301 21 10.6843 21 10.4319V5.56811C21 5.31572 21.0001 5.06993 20.9831 4.86178C20.9644 4.63318 20.9203 4.36345 20.782 4.09202C20.5903 3.7157 20.2843 3.40974 19.908 3.21799C19.6366 3.07969 19.3668 3.03563 19.1382 3.01695C18.9301 2.99994 18.6843 2.99997 18.4319 3L15.5681 3Z"/>
            <path
                d="M5.56811 11C5.31573 11 5.06993 10.9999 4.86178 11.017C4.63318 11.0356 4.36345 11.0797 4.09202 11.218C3.7157 11.4097 3.40974 11.7157 3.21799 12.092C3.07969 12.3634 3.03563 12.6332 3.01695 12.8618C2.99994 13.0699 2.99997 13.3157 3 13.5681L3 18.4319C2.99997 18.6843 2.99994 18.9301 3.01695 19.1382C3.03563 19.3668 3.07969 19.6366 3.21799 19.908C3.40974 20.2843 3.7157 20.5903 4.09202 20.782C4.36345 20.9203 4.63318 20.9644 4.86178 20.9831C5.06994 21.0001 5.31574 21 5.56812 21H8.43189C8.68427 21 8.93007 21.0001 9.13824 20.9831C9.36683 20.9644 9.63656 20.9203 9.90799 20.782C10.2843 20.5903 10.5903 20.2843 10.782 19.908C10.9203 19.6366 10.9644 19.3668 10.9831 19.1382C11.0001 18.9301 11 18.6843 11 18.4319V13.5681C11 13.3157 11.0001 13.0699 10.9831 12.8618C10.9644 12.6332 10.9203 12.3634 10.782 12.092C10.5903 11.7157 10.2843 11.4097 9.90799 11.218C9.63656 11.0797 9.36683 11.0356 9.13824 11.017C8.93008 10.9999 8.68429 11 8.43192 11H5.56811Z"/>
            <path
                d="M5.56811 3C5.31573 2.99997 5.06993 2.99994 4.86178 3.01695C4.63318 3.03563 4.36345 3.07969 4.09202 3.21799C3.7157 3.40974 3.40974 3.7157 3.21799 4.09202C3.07969 4.36345 3.03563 4.63318 3.01695 4.86178C2.99994 5.06993 2.99997 5.31572 3 5.56809L3 6.4319C2.99997 6.68428 2.99994 6.93008 3.01695 7.13824C3.03563 7.36683 3.07969 7.63656 3.21799 7.90799C3.40974 8.28431 3.7157 8.59027 4.09202 8.78202C4.36345 8.92032 4.63318 8.96438 4.86178 8.98306C5.06993 9.00007 5.31572 9.00004 5.56811 9.00001H8.4319C8.68429 9.00004 8.93008 9.00007 9.13824 8.98306C9.36683 8.96438 9.63656 8.92032 9.90799 8.78202C10.2843 8.59027 10.5903 8.28431 10.782 7.90799C10.9203 7.63656 10.9644 7.36683 10.9831 7.13824C11.0001 6.93009 11 6.68431 11 6.43194V5.56811C11 5.31574 11.0001 5.06992 10.9831 4.86178C10.9644 4.63318 10.9203 4.36345 10.782 4.09202C10.5903 3.7157 10.2843 3.40974 9.90799 3.21799C9.63656 3.07969 9.36683 3.03563 9.13824 3.01695C8.93008 2.99994 8.68429 2.99997 8.43192 3L5.56811 3Z"/>
            <path
                d="M15.5681 15C15.3157 15 15.0699 14.9999 14.8618 15.017C14.6332 15.0356 14.3634 15.0797 14.092 15.218C13.7157 15.4097 13.4097 15.7157 13.218 16.092C13.0797 16.3634 13.0356 16.6332 13.017 16.8618C12.9999 17.0699 13 17.3157 13 17.5681V18.4319C13 18.6843 12.9999 18.9301 13.017 19.1382C13.0356 19.3668 13.0797 19.6366 13.218 19.908C13.4097 20.2843 13.7157 20.5903 14.092 20.782C14.3634 20.9203 14.6332 20.9644 14.8618 20.9831C15.0699 21.0001 15.3157 21 15.5681 21H18.4319C18.6843 21 18.9301 21.0001 19.1382 20.9831C19.3668 20.9644 19.6366 20.9203 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C20.9203 19.6366 20.9644 19.3668 20.9831 19.1382C21.0001 18.9301 21 18.6843 21 18.4319V17.5681C21 17.3157 21.0001 17.0699 20.9831 16.8618C20.9644 16.6332 20.9203 16.3634 20.782 16.092C20.5903 15.7157 20.2843 15.4097 19.908 15.218C19.6366 15.0797 19.3668 15.0356 19.1382 15.017C18.9301 14.9999 18.6843 15 18.4319 15H15.5681Z"/>
        </svg>
    )
}
