import React, { Dispatch, SetStateAction, useContext } from 'react'
import CustomImage from '../../../../components/customImage/customImage'
import { DropDownIcon } from '../../../../assets/icons/dropDownIcon'
import { headerItems } from '../../../../assets/constants/headerItems'
import { InitialStateContext } from '../../../../App'
import { NavLink, useLocation } from 'react-router-dom'
import { FaqIcon } from '../../../../assets/icons/faqIcon'
import { WhyIcon } from '../../../../assets/icons/whyIcon'
import {
    AUTH_LOGIN_URL,
    AUTH_LOGOUT_URL,
    AUTH_SIGNUP_URL_PRICING,
} from '../../../../constant/auth'
import useProfileQuery from '../../../../queries/use-profile-query'
import { LocalStorageItems } from '../../../../constant/localStorageItems'
import { Pricing } from './pricing'

interface IProps {
    setBurgerMenuOpen?: Dispatch<SetStateAction<boolean>>
    isLending?: boolean
}

export const UserName = ({ setBurgerMenuOpen, isLending }: IProps) => {
    const { userData } = useProfileQuery()
    const { modal, setModal } = useContext(InitialStateContext)
    const { pathname } = useLocation()

    const userName = userData?.name || ''

    const logOut = () => {
        setBurgerMenuOpen?.(false)
        localStorage.removeItem(LocalStorageItems.TOKEN)
        window.open(AUTH_LOGOUT_URL, '_self')
    }

    return userName ? (
        userName.trim() ? (
            <div className="hoverPlace rounded-lg z-[100] relative flex flex-col lg:flex-row lg:items-center cursor-pointer bg-[transparent] lg:bg-darkBlueOpacity lg:h-[42px] px-[10px]">
                <div className="flex items-center h-[50px]">
                    <CustomImage
                        className="h-[25px] lg:h-[18px] lg:min-h-[18px] lg:min-w-[18px] min-w-[25px] min-h-[25px] w-[25px] lg:w-[18px]"
                        width={18}
                        height={18}
                        mockImg="/images/person.png"
                    />
                    <span className="text-white text-sm lg:text-base pr-3 pl-1">
                        {userName}
                    </span>
                    <DropDownIcon className="w-[10px] h-[14px] stroke-white rotate-[-90deg] hidden lg:block" />
                </div>
                <div className="backdrop-blur rounded-lg lg:absolute top-[42px] right-0 flex-col w-max bg-[transparent] lg:bg-darkBlueOpacity overflow-hidden hoverBlock flex lg:hidden">
                    {headerItems.map((el) =>
                        el.link ? (
                            <NavLink
                                key={el.name}
                                to={el.link}
                                className={`${pathname === el.link ? 'text-violet' : 'text-white'} lg:hover:bg-blue h-[50px] lg:h-[42px] flex w-full items-center text-sm lg:text-base lg:px-3`}
                            >
                                {el.icon
                                    ? el.icon(
                                          `w-[25px] lg:w-[20px] h-[25px] lg:h-[20px] ${pathname === el.link ? el.className.split(',')[1] : el.className.split(',')[0]} mr-2`
                                      )
                                    : null}
                                <span>{el.name}</span>
                            </NavLink>
                        ) : (
                            <div
                                key={el.name}
                                onClick={logOut}
                                className="text-white lg:hover:bg-blue h-[50px] lg:h-[42px] w-full flex items-center text-sm lg:text-base lg:px-3 border-0 lg:border-t border-blue"
                            >
                                {el.icon
                                    ? el.icon(
                                          `w-[25px] lg:w-[20px] h-[25px] lg:h-[20px] ${el.className} mr-2`
                                      )
                                    : null}
                                <span>{el.name}</span>
                            </div>
                        )
                    )}
                </div>
            </div>
        ) : (
            <></>
        )
    ) : (
        <div className="flex lg:items-center flex-col-reverse lg:flex-row py-[10px] lg:py-0">
            {/* {isLending ? null : (
                <>
                    <div
                        onClick={() => setModal('img')}
                        className={`cursor-pointer ${modal ? 'text-violet' : 'text-white'} mt-3 lg:mt-0 hidden sm:flex items-center text-sm lg:text-base px-[10px] lg:px-0 lg:mr-10 hover:text-violet hoverFillLink font-poppinsBold gap-2`}
                    >
                        <WhyIcon />
                        <span>Why TAZZ</span>
                    </div>
                    <NavLink
                        to="/faq"
                        className={`${pathname === '/faq' ? 'text-violet' : 'text-white'} flex items-center text-sm lg:text-base px-[10px] lg:px-0 lg:mr-10 hover:text-violet hoverFillLink font-poppinsBold gap-2`}
                    >
                        <FaqIcon />
                        <span>FAQ</span>
                    </NavLink>
                </>
            )} */}
            <div className="flex items-center gap-2 mb-3 lg:mb-0">
                <a
                    href={AUTH_LOGIN_URL}
                    className={`${isLending ? 'border border-blue-700' : ''} bg-blueOpacity lg:bg-darkBlue h-[42px] px-4 flex items-center text-white font-poppinsBold rounded-lg`}
                >
                    <CustomImage
                        className="h-[18px] w-[18px]"
                        width={18}
                        height={18}
                        mockImg="/images/person.png"
                    />
                    <span className="ml-2">Sign in</span>
                </a>

                <a
                    href={AUTH_SIGNUP_URL_PRICING}
                    className="bg-white h-[42px] px-4 flex items-center text-blackOpacity font-poppinsBold rounded-lg"
                >
                    Get Started
                </a>
                <div className="lg:hidden">
                    <Pricing />
                </div>
            </div>
        </div>
    )
}
