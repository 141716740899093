type Props = {
    fill?: string
}

export const DistributeVerticalIcon = ({ fill = 'white' }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M9.96758 16C8.84747 16 8.28742 16 7.8596 15.782C7.48327 15.5903 7.17731 15.2843 6.98557 14.908C6.76758 14.4802 6.76758 13.9201 6.76758 12.8V11.2C6.76758 10.0799 6.76758 9.51984 6.98557 9.09202C7.17731 8.71569 7.48327 8.40973 7.8596 8.21799C8.28742 8 8.84747 8 9.96758 8H15.5676C16.6877 8 17.2477 8 17.6756 8.21799C18.0519 8.40973 18.3578 8.71569 18.5496 9.09202C18.7676 9.51984 18.7676 10.0799 18.7676 11.2V12.8C18.7676 13.9201 18.7676 14.4802 18.5496 14.908C18.3578 15.2843 18.0519 15.5903 17.6756 15.782C17.2477 16 16.6877 16 15.5676 16H9.96758Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.76758 19C4.21529 19 3.76758 19.4477 3.76758 20C3.76758 20.5523 4.21529 21 4.76758 21H20.7676C21.3199 21 21.7676 20.5523 21.7676 20C21.7676 19.4477 21.3199 19 20.7676 19H4.76758ZM4.76758 3C4.21529 3 3.76758 3.44772 3.76758 4C3.76758 4.55228 4.21529 5 4.76758 5H20.7676C21.3199 5 21.7676 4.55228 21.7676 4C21.7676 3.44772 21.3199 3 20.7676 3H4.76758Z"
                fill={fill}
            />
        </svg>
    )
}
