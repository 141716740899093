import { useLocation } from 'react-router-dom'
import { AddIcon } from '../../../../assets/icons/addIcon'
import React, { useContext } from 'react'
import { InitialStateContext } from '../../../../App'
import { CloseIcon } from '../../../../assets/icons/closeIcon'
import { LogoIcon } from '../../../../assets/icons/logoIcon'

interface IProps {
    access?: boolean
}

export const Logo = ({ access }: IProps) => {
    const { pathname } = useLocation()
    const { setLandingModal, landings } = useContext(InitialStateContext)

    const handleClick = () => {
        window.location.href = '/'
    }
    return (
        <div className="relative flex items-center">
            <div className="flex cursor-pointer" onClick={handleClick}>
               <LogoIcon />
            </div>
            {landings.includes(pathname.slice(1)) && access ? (
                <CloseIcon
                    className="absolute w-[30px] h-[30px] stroke-white right-[-55px] cursor-pointer"
                    onClick={() => setLandingModal('remove')}
                />
            ) : null}
            {access ? (
                <AddIcon
                    className="w-[30px] h-[30px] stroke-white absolute right-[-25px] cursor-pointer"
                    onClick={() => setLandingModal('add')}
                />
            ) : null}
        </div>
    )
}
