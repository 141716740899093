import { Boxing } from '../icons/boxing'
import { Rugby } from '../icons/rugby'
import { HorseIcon } from '../icons/sports/horseIcon'
import { Golf } from '../icons/sports/golf'
import { Tennis } from '../icons/tennis'
import { BoxingIcon } from '../icons/boxingIcon'
import { ChannelsIcon } from '../icons/channelsIcon'
import { SoccerIcon } from '../icons/soccerIcon'
import { IceHokey } from '../icons/iceHokey'
import { AthleticsIcon } from '../icons/athleticsIcon'
import { MotoSport } from '../icons/motoSport'
import { EuroLeague } from '../icons/sports/euroLeague'
import { SPORTS } from '../../constant/sports'
import CustomImage from '../../components/customImage/customImage'
import { MoreIcon } from '../icons/moreIcon'

export interface Link {
    key: string
    value: string
}

export interface ILink {
    name: SPORTS
    img: string
    svg?: Function
    link?: Link
    channelName?: string
    leagueId?: string
}

export const links: ILink[] = [
    {
        name: SPORTS.CHANNELS,
        img: '',
        svg: (className: string) => <ChannelsIcon className={className} />,
    },
    {
        name: SPORTS.SOCCER,
        img: '',
        svg: (className: string) => <SoccerIcon className={className} />,
        link: { key: 'sport_uuid', value: '8b84f073' },
        channelName: '',
        leagueId: '53662bee',
    },
    {
        name: SPORTS.TENNIS,
        img: '',
        svg: (className: string) => <Tennis className={className} />,
        link: { key: 'sport_uuid', value: '821fda97' },
        channelName: 'Tennis Channel',
        leagueId: 'b8cbdefa',
    },
    {
        name: SPORTS.NFL,
        img: 'league/9465',
        link: { key: 'sport_uuid', value: 'd4d986e8' },
        channelName: 'NFL Network',
        leagueId: '2090c62e',
    },
    {
        name: SPORTS.NCAAF,
        img: 'leagues/logos/3T4hFax9CX1p2YzpbOXhoUNP4vspvu9LyUEET5jHMk7Mel7j5s',
        link: { key: 'sport_uuid', value: '68689ed4' },
        channelName: 'ESPN',
        leagueId: '0a48805d',
    },
    {
        name: SPORTS.NBA,
        img: 'league/2329',
        link: { key: 'sport_uuid', value: '7281de84' },
        channelName: 'NBA TV',
        leagueId: '104a40f5',
    },
    {
        name: SPORTS.MLB,
        img: 'league/11205',
        link: { key: 'sport_uuid', value: '248a0280' },
        channelName: 'MLB Network',
        leagueId: 'bdcbe0d4',
    },
    {
        name: SPORTS.NHL,
        img: 'league/957',
        link: { key: 'sport_uuid', value: '4f452860' },
        channelName: 'NHL Network',
        leagueId: 'e21e0c55',
    },
    {
        name: SPORTS.F1,
        img: 'leagues/logos/uTIsNgoWyjgxQZ8MOasKX08LQ1x513ntz1bpLxBoAuYhtRmEdW',
        link: { key: 'sport_uuid', value: '7bcf26a6' },
        channelName: 'Sky Sports F1',
        leagueId: '2a6592f5',
    },
    {
        name: SPORTS.EUROLEAGUE,
        img: '',
        svg: (className: string) => <EuroLeague className={className} />,
        link: { key: 'sport_uuid', value: '8062aaac' },
        channelName: '',
        leagueId: '88241a03',
    },
    {
        name: SPORTS.NCAAB,
        img: 'leagues/logos/GTaRmQeKR59m3XZxACNARZdJLw0VBuZM3VhFpU4MY1zp2hZYMo',
        link: { key: 'sport_uuid', value: '7316f32d' },
        channelName: 'ESPN',
        leagueId: 'd3a02a28',
    },
    {
        name: SPORTS.BOXING,
        img: '',
        svg: (className: string) => <BoxingIcon className={className} />,
        link: { key: 'sport_uuid', value: 'f415efb9' },
        channelName: 'ESPN',
        leagueId: 'ed41106a',
    },
    {
        name: SPORTS.MMA,
        img: '',
        svg: (className: string) => <Boxing className={className} />,
        link: { key: 'sport_uuid', value: '3f7a342d' },
        channelName: 'ESPN News',
        leagueId: '87caf79d',
    },
    {
        name: SPORTS.CFL,
        img: '',
        link: { key: 'sport_uuid', value: '688fd539' },
        svg: () => (
            <CustomImage
                mockImg="/images/newLendingImg/channels/CFL.png"
                width="25"
            />
        ),
        channelName: '',
        leagueId: '764e328f',
    },
    // {
    //     name: SPORTS.NCAA_BASEBALL,
    //     img: '',
    //     svg: () => (
    //         <CustomImage
    //             mockImg="/images/newLendingImg/channels/ncaaBaseball.png"
    //             width="20"
    //             height="20"
    //         />
    //     ),
    //     link: { key: 'sport_uuid', value: '372d6333' },
    //     channelName: 'ESPN U_tazz',
    //     leagueId: 'c56d2afc',
    // },
    {
        name: SPORTS.GOLF,
        img: '',
        svg: (className: string) => <Golf className={className} />,
        link: { key: 'sport_uuid', value: '95917238' },
        channelName: 'ESPN',
        leagueId: '48d45d90',
    },
    {
        name: SPORTS.RUGBY,
        img: '',
        svg: (className: string) => <Rugby className={className} />,
        link: { key: 'sport_uuid', value: 'd79a1631' },
        channelName: '',
        leagueId: '19f13fe4',
    },
    {
        name: SPORTS.MOTORSPORTS,
        img: '',
        svg: (className: string) => <MotoSport className={className} />,
        link: { key: 'sport_uuid', value: 'ec2cdc35' },
        channelName: '',
        leagueId: 'f7302e8f',
    },
    {
        name: SPORTS.ATHLETICS,
        img: '',
        svg: (className: string) => <AthleticsIcon className={className} />,
        link: { key: 'sport_uuid', value: '0b2811ef' },
        channelName: '',
        leagueId: 'f523679a',
    },
    //{
    //    name: SPORTS.BASKETBALL,
    //    img: '',
    //    link: { key: 'sport_uuid', value: 'ebd43f84' },
    //    svg: () => (
    //        <CustomImage mockImg="/images/newLendingImg/channels/basketball.png" />
    //    ),
    //    channelName: '',
    //    leagueId: 'b45797c0',
    //},
    //{
    //    name: SPORTS.VOLLEYBALL,
    //    img: '',
    //    link: { key: 'sport_uuid', value: '96230538' },
    //    svg: () => (
    //        <CustomImage mockImg="/images/newLendingImg/channels/basketball.png" />
    //    ),
    //    channelName: '',
    //    leagueId: '63e95c34'
    //},
    //{
    //    name: SPORTS.HANDBALL,
    //    img: '',
    //    link: { key: 'sport_uuid', value: '9c869ed1' },
    //    svg: () => (
    //        <CustomImage mockImg="/images/newLendingImg/channels/basketball.png" width="25" />
    //    ),
    //    channelName: '',
    //    leagueId: 'b8e3b0c2'
    //},
    //{
    //    name: SPORTS.WATER_POLO,
    //    img: '',
    //    link: { key: 'sport_uuid', value: '8cca17c7' },
    //    svg: () => (
    //        <CustomImage
    //            mockImg="/images/newLendingImg/channels/basketball.png"
    //            width="25"
    //        />
    //    ),
    //    channelName: '',
    //    leagueId: '5970fbd8',
    //},
    //{
    //    name: SPORTS.OLYMPIC_GAMES,
    //    img: '',
    //    link: { key: 'sport_uuid', value: '6fa8a2a5' },
    //    svg: () => (
    //        <CustomImage
    //            mockImg="/images/newLendingImg/channels/basketball.png"
    //            width="25"
    //        />
    //    ),
    //    channelName: '',
    //    leagueId: '63e95c34',
    //},
    //{
    //    name: SPORTS.TOUR_DE_FRANCE,
    //    img: '',
    //    link: { key: 'sport_uuid', value: '5d9a63a9' },
    //    svg: () => (
    //        <CustomImage
    //            mockImg="/images/newLendingImg/channels/tour_de_france.png"
    //            width={25}
    //        />
    //    ),
    //    channelName: '',
    //    leagueId: 'fe4ebb36',
    //},
    //{
    //    name: SPORTS.ICE_HOCKEY,
    //    img: '',
    //    svg: (className: string) => <IceHokey className={className} />,
    //    link: { key: 'sport_uuid', value: '275decc4' },
    //    channelName: '',
    //    leagueId: 'a8aa164c',
    //},
    //{
    //    name: SPORTS.HORSE_RACING,
    //    img: '',
    //    svg: (className: string) => <HorseIcon className={className} />,
    //    link: { key: 'sport_uuid', value: 'a6253c14' },
    //    channelName: '',
    //    leagueId: 'ffadbd5c',
    //},

    {
        name: SPORTS.OTHER,
        img: '',
        svg: (className: string) => (
            <MoreIcon className={`${className} text-white`} />
        ),
        link: { key: 'sport_uuid', value: '45657ac3' },
        channelName: '',
        leagueId: '87c7c1c7',
    },
]
