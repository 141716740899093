import React from 'react'

export const CoinDollarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10 9C10 8.44772 10.4477 8 11 8H13C13.5523 8 14 8.44772 14 9C14 9.55228 14.4477 10 15 10C15.5523 10 16 9.55228 16 9C16 7.34315 14.6569 6 13 6V5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V6C9.34315 6 8 7.34315 8 9V9.55848C8 10.8498 8.82629 11.9962 10.0513 12.4045L13.3162 13.4928C13.7246 13.6289 14 14.0111 14 14.4415V15C14 15.5523 13.5523 16 13 16H11C10.4477 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14C8.44772 14 8 14.4477 8 15C8 16.6569 9.34315 18 11 18V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V18C14.6569 18 16 16.6569 16 15V14.4415C16 13.1502 15.1737 12.0038 13.9487 11.5955L10.6838 10.5072C10.2754 10.3711 10 9.98891 10 9.55848V9Z"
                fill="white"
            />
        </svg>
    )
}
