import React, {
    memo,
    Suspense,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react'
import { usePopper } from 'react-popper'
import { links, ILink } from '../../assets/constants/linksItems'
import { LinkItem } from '../../components/linkMainPageFooterItem/linkItem'
import useScreenSize from '../../hooks/useScreenSize'
import { MoreIcon } from '../../assets/icons/moreIcon'
import CustomImage from '../../components/customImage/customImage'
import { InitialStateContext } from '../../App'

const sizeToItemsCount: Record<number, number> = {
    1280: 9,
    1024: 7,
    920: 6,
    // 640: 4,
    // 520: 3,
    // 0: 2,
}

const DropdownItem = ({
    item,
    isActive,
    isLast,
    onClick,
}: {
    onClick: () => void
    item: ILink
    isActive: boolean
    isLast?: boolean
}) => {
    return (
        <li
            onClick={onClick}
            className={`p-2 cursor-pointer hover:bg-darkBlueOpacity1 rounded-lg text-white flex items-center gap-3 ${!isLast ? 'border-darkBlueOpacity0 border-b-2' : ''}`}
        >
            {item.img ? (
                <CustomImage
                    width={20}
                    height={20}
                    src={`uploads/${item.img}.png`}
                    className="h-[20px] sm:h-[25px] w-[20px] sm:w-[25px]"
                    alt={item.name}
                />
            ) : item?.svg ? (
                item.svg(
                    `h-[20px] sm:h-[25px] w-[20px] sm:w-[25px]  fill-${isActive ? 'cyan' : 'white'}`
                )
            ) : (
                ''
            )}
            {item.name}
        </li>
    )
}

export const Navbar = memo(() => {
    const { width } = useScreenSize()
    const [open, setOpen] = useState(false)
    const { currentSport, setCurrentSport } = useContext(InitialStateContext)

    const parentRef = useRef(null)
    const popperRef = useRef(null)
    const { styles, attributes } = usePopper(
        parentRef.current,
        popperRef.current,
        {
            placement: 'bottom-end',
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['bottom-end'],
                    },
                },
            ],
        }
    )

    const getItemsCountToDisplay = useCallback((width: number) => {
        const keys = Object.keys(sizeToItemsCount)
            .map(Number)
            .sort((a, b) => b - a)
        for (const key of keys) {
            if (width >= key) {
                return sizeToItemsCount[key]
            }
        }
        return -1
    }, [])

    const itemsCountToDisplay = useMemo(
        () => getItemsCountToDisplay(width),
        [width]
    )

    const sportsLinks = useMemo(
        () =>
            itemsCountToDisplay === -1
                ? links
                : links.slice(0, itemsCountToDisplay),
        [itemsCountToDisplay]
    )

    const dropdownItems = useMemo(
        () => links.slice(itemsCountToDisplay),
        [itemsCountToDisplay]
    )

    const handleMenuItemClick = (elem: ILink) => {
        setCurrentSport?.(elem.name)
        setOpen(false)
    }

    return (
        <div
            id="navScrollDiv"
            className=" flex w-full my-6  unique:overflow-x-hidden overflow-x-scroll thinScrollbar p-2 bg-whiteOpacity1 rounded-[16px] gap-[12px] justify-between"
        >
            {sportsLinks.map((el) => (
                <LinkItem
                    currentSport={currentSport}
                    onClick={() => handleMenuItemClick(el)}
                    className=""
                    key={el.name}
                    item={el}
                />
            ))}
            <div
                ref={parentRef}
                onClick={() => setOpen(!open)}
                className={`unique:flex hidden items-center px-[10px] cursor-pointer hover:bg-darkBlueOpacity1 rounded-[8px] ${open ? 'bg-darkBlueOpacity1' : ''}`}
            >
                <MoreIcon className="h-[20px] sm:h-[25px] w-[20px] sm:w-[25px] m-auto fill-white" />
                <span className="ml-[10px] text-white text-[16px] font-poppinsRegular overflow-hidden text-ellipsis whitespace-nowrap capitalize">
                    More
                </span>

                <div
                    ref={popperRef}
                    {...attributes.popper}
                    style={styles.popper}
                >
                    <div
                        className={` backdrop-blur ${!open && 'hidden'} bg-greenOpacity thinScrollbar overflow-y-scroll  min-w-max h-max rounded-lg p-2`}
                    >
                        <Suspense>
                            <ul>
                                {dropdownItems.map((item, index) => (
                                    <DropdownItem
                                        onClick={() =>
                                            handleMenuItemClick(item)
                                        }
                                        key={item.name}
                                        item={item}
                                        isActive={currentSport === item.name}
                                        isLast={
                                            index === dropdownItems.length - 1
                                        }
                                    />
                                ))}
                            </ul>
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
})
