import React from 'react'

type Props = {
    className?: string
}

export const CrossAltIcon = ({ className }: Props) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect x="0.25" width="24" height="24" rx="12" fill="#FF4D41" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.59313 8.34314C8.20261 8.73367 8.20261 9.36683 8.59313 9.75736L10.8358 12L8.59313 14.2426C8.20261 14.6332 8.20261 15.2663 8.59313 15.6569C8.98366 16.0474 9.61682 16.0474 10.0073 15.6569L12.25 13.4142L14.4926 15.6569C14.8832 16.0474 15.5163 16.0474 15.9068 15.6569C16.2974 15.2663 16.2974 14.6332 15.9068 14.2426L13.6642 12L15.9068 9.75736C16.2974 9.36684 16.2974 8.73367 15.9068 8.34315C15.5163 7.95262 14.8832 7.95262 14.4926 8.34315L12.25 10.5858L10.0073 8.34315C9.61682 7.95262 8.98366 7.95262 8.59313 8.34314Z"
                fill="white"
            />
        </svg>
    )
}
