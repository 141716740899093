import React, { useRef } from 'react'
import classNames from 'classnames'

import useClickOutside from './useClickOutside'
import useTableContent from './useTableContent'
import { blackBackGround } from '../../../../../assets/constants/themeBackgrounds'

type Props = {
    closeModal: () => void
}

const WhyTableModal = ({ closeModal }: Props) => {
    const modalRef = useRef<HTMLDivElement | null>(null)

    const { header, data: tableData, footer } = useTableContent()

    useClickOutside(modalRef, closeModal)

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1000]">
            <div
                ref={modalRef}
                style={{
                    background: blackBackGround,
                }}
                className={`bg-cover rounded-lg max-w-5xl w-full overflow-auto px-12 py-10 relative`}
            >
                <h2 className="text-[24px] font-bold my-4 text-center text-white w-full py-2 bg-whiteOpacity1 rounded-2xl backdrop-blur-lg">
                    Why choose TAZZ.TV? Let's compare
                </h2>
                <table className="w-full border-separate border-black rounded text-white font-poppinsRegular">
                    <thead className="mb-5">
                        <tr>
                            {header.map((head, index) => (
                                <td
                                    key={head.text}
                                    className={`
                                                text-center
                                                uppercase
                                                text-[24px]
                                                ${
                                                    index === 1
                                                        ? 'font-dmSerifText'
                                                        : 'font-poppinsRegular font-bold'
                                                }`}
                                >
                                    {head.text}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index, array) => {
                            return (
                                <tr
                                    key={index}
                                    className={classNames({
                                        'bg-green3 font-bold': index === 0,
                                    })}
                                >
                                    <th
                                        className={classNames(
                                            'px-4 py-2.5 text-left bg-darkBlueOpacity0',
                                            {
                                                'rounded-tl-2xl bg-greenOpacity2':
                                                    index === 0,
                                                'rounded-bl-2xl':
                                                    index === array.length - 1,
                                            }
                                        )}
                                    >
                                        {row.firstColumn}
                                    </th>
                                    <td
                                        className={classNames(
                                            'px-4 py-2.5 text-center',
                                            {
                                                'bg-greenOpacity2 font-normal':
                                                    index !== 0,
                                            }
                                        )}
                                    >
                                        {row.current}
                                    </td>
                                    <td
                                        className={classNames(
                                            'px-4 py-2.5 text-center',
                                            {
                                                'bg-greenOpacity2 font-normal':
                                                    index !== 0,
                                            }
                                        )}
                                    >
                                        {row.free}
                                    </td>
                                    <td
                                        className={classNames(
                                            'px-4 py-2.5 text-center bg-greenOpacity2',
                                            {
                                                'rounded-tr-2xl': index === 0,
                                                'font-normal': index !== 0,
                                                'rounded-br-2xl':
                                                    index === array.length - 1,
                                            }
                                        )}
                                    >
                                        {row.official}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {footer.map((foot) => (
                                <td key={foot.id}>{foot.content}</td>
                            ))}
                        </tr>
                    </tfoot>
                </table>
                <button
                    onClick={closeModal}
                    className="absolute top-5 right-10 text-white cursor-pointer"
                >
                    x
                </button>
            </div>
        </div>
    )
}

export default WhyTableModal
